export const ThreeColumnRouteLinks: any = {
	analyticsLink: '/analytics',
	analyticsOverviewLink: '/analytics/overview',
	broadcastHomeLink: '/broadcast',
	broadCastHelpLink: '/broadcast/help',
	campaignsLink: '/broadcast/campaigns',
	broadcastHistoryLink: '/broadcast/published',
	executionLink: '/broadcast/execution',
	engagementLink: '/broadcast/engagement',
	conversionLink: '/broadcast/conversion',
	broadcastAnalyticsSelected: '/broadcast/published/view',
	clickToWhatsappAdsLink: '/broadcast/clickToWhatsappAds',
	scheduledBroadcastLink: '/broadcast/scheduled',
	draftBroadcastLink: '/broadcast/draft',
	createBroadcast: '/broadcast/create',
	exportHistoryLink: '/broadcast/exportHistory',
	campaignAnalyticsSelected: '/broadcast/campaigns/view',
	broadcastMessageTemplates: '/broadcast/templates',
	createBroadcastMessageTemplates: '/broadcast/templates/create',
	broadcastEmailTemplates: '/broadcast/email-templates',
	storeHomeLink: '/store',
	storeCustomersLink: '/store/segments',
	storeProductsLink: '/store/products',
	storeConversionTriggersLink: '/store/conversion',
	storeFeatureConfiguratorLink: '/store/feature-configurator',
	storeSetupLink: '/store/setup',
	userRetentionLink: '/analytics/user/retention',
	analyticsBotRespLink: '/analytics/bot/response',
	analyticsBotEngageLink: '/analytics/bot/engagement',
	analyticsChannelLink: '/analytics/bot/channels',
	genAIQueryAnalysis: '/analytics/gen-ai/query-analysis',
	analyticsChannelAgents: '/analytics/livechat/agents',
	analyticsSummaryLink: '/analytics/livechat/summary',
	analyticsPerformanceLink: '/analytics/livechat/agent-performance',
	analyticsConversionsLink: '/analytics/livechat/agent-conversions',
	purchaseFunnelLink: '/analytics/livechat/purchase-funnel',
	productAnalyticsLink: '/analytics/livechat/product-analytics',
	newanalyticsPerformanceLink: '/analytics/livechat/new-agent-performance',
	analyticsProductivityLink: '/analytics/livechat/agent-productivity',
	liveChatDetails: '/analytics/livechat/details',
	slaBreaches: '/analytics/livechat/sla-breaches',
	feedbackSummary: '/analytics/livechat/feedback',
	buttonClickAnalysis: '/analytics/usage/button-click-analysis',
	platformInteractions: '/analytics/usage/platform-interactions',
	automationEfficiency: '/analytics/livechat/automation-efficiency',
	configureHomeLink: '/configure',
	configureOverviewLink: '/configure/overview',
	configureDetailsLink: '/configure/bot-details',
	configureAttributesLink: '/configure/attributes',
	configurePublishLink: '/configure/publish',
	configureVersionHistoryLink: '/configure/version-history',
	configureDisplayLink: '/configure/display',
	configureAttributesHistoryLink: '/configure/attributes/change-history/:attrId',
	configureLcLink: '/configure/live-chat',
	configureLcSetupLink: '/configure/live-chat/setup',
	configureExternalLcLink: '/configure/external-livechat',
	configureLcSettingsLink: '/configure/live-chat/:setting',
	configureDeploymentLink: '/configure/deployment',
	configureDepEmailLink: '/configure/deployment/email',
	configureDepFbLink: '/configure/deployment/facebook',
	configureDepInstaLink: '/configure/deployment/instagram',
	configureDepGbmLink: '/configure/deployment/gbm',
	configureDepKikLink: '/configure/deployment/kik',
	configureDepLineLink: '/configure/deployment/line',
	configureDepTeamsLink: '/configure/deployment/msTeams',
	configureDepMobileLink: '/configure/deployment/mobile',
	configureDepSkypeBusLink: '/configure/deployment/skypeBusiness',
	configureDepSkypeLink: '/configure/deployment/skype',
	configureDepWebLink: '/configure/deployment/website',
	configureDepSlackLink: '/configure/deployment/slack',
	configureDepTelegramLink: '/configure/deployment/telegram',
	configureDepTwitterLink: '/configure/deployment/twitter',
	configureDepViberLink: '/configure/deployment/viber',
	configureDepWeChatLink: '/configure/deployment/weChat',
	cofigureDepWhatsappBusinessProfileLink: '/configure/deployment/whatsapp/businessProfile',
	configureDepWhatsappLink: '/configure/deployment/whatsapp',
	configureAgentProductivity: '/configure/agent-productivity',
	configureAdvancedLink: '/configure/advanced',
	configureAdvVoiceLink: '/configure/advanced/voice',
	configureAdvFormattingLink: '/configure/advanced/formatting',
	configureAdvSecurityLink: '/configure/advanced/security',
	conversationOverviewLink: '/analytics/livechat/conversation-overview',
	integrationsHomeLink: '/integrations',
	cloudIntegrationsLink: '/integrations/cloud-integrations',
	engatiApiLink: '/integrations/platform-api',
	c2eBridgeLink: '/integrations/c2e-bridge',
	apiLogsLink: '/integrations/api-logs',
	channelLogsLink: '/integrations/channel-logs',
	paymentLogsLink: '/integrations/payment-logs',
	whatsAppHomeLink: '/whatsApp',
	whatsAppNumbersLink: '/whatsApp/numbers',
	whatsAppFlowsLink: '/whatsApp/flows',
	confiureLanguagesLink: '/configure/languages',
	confiureLanguagesHistoryLink: '/configure/languages/keys-upload-history',
	trainHomeLink: '/train',
	trainCognitiveLink: '/train/cognitive-search',
	trainEntitiesLink: '/train/entities',
	trainFaqLink: '/train/faq',
	trainConfigSynonymsLink: '/train/faq/configure-synonyms',
	trainUploadHistoryLink: '/train/faq/upload-history',
	trainCategoriesLink: '/train/categories',
	trainOverviewLink: '/train/overview',
	trainSettingsLink: '/train/settings',
	trainTabularInpLink: '/train/tabular-input',
	configureIntegrationsLink: '/configure/integrations',
	gaIntegrationLink: '/configure/integrations/google-analytics',
	googleTranslateIntegrationLink: '/configure/integrations/google-translate',
	gorgiasIntegrationLink: '/configure/integrations/gorgias',
	zendeskIntegrationLink: '/configure/integrations/zendesk',
	salesforceIntegrationLink: '/configure/integrations/salesforce',
	shiprocketIntegrationLink: '/configure/integrations/shiprocket',
	razorpayIntegrationLink: '/configure/integrations/razorpay',
	googleAdminIntegrationLink: '/configure/integrations/google-admin',
	woocommerceIntegrationLink: '/configure/integrations/woocommerce',
	freshdeskIntegrationLink: '/configure/integrations/freshdesk',
	smsIntegrationLink: '/configure/integrations/sms',
	eSenseGPTIntegrationLink: '/configure/integrations/esensegpt',
	GPTIntegrationLink: '/configure/integrations/GPT',
	trainResponsesLink: '/train/responses',
	trainExpHistoryLink: '/train/responses/export-history',
	trainFileUplAnalyticsLink: '/train/responses/file-upload-analytics',
	trainApiErrorHistoryLink: '/train/responses/api-error-history',
	configureShopifyLink: '/configure/integrations/shopify',
	whatsappComplianceInfoLink: '/configure/deployment/whatsapp/compliance',
	agentAssistanceFeedBackLink: '/analytics/livechat/agent-assistance-feedback',
	pulseLink: '/broadcast/pulse',
	pulseReport: '/broadcast/report',
	socialInboxFacebook: '/social-inbox/facebook/posts',
	socialInboxInstagram: '/social-inbox/instagram/posts',
	socialInboxFacebookPost: ':cid',
	socialInboxInstagramPost: ':cid',
	aiAgentsLink: '/agents',
	aiAgentsManagementLink: '/agents/build',
	createAIAgentLink: '/agents/create-agent',
	viewAgentDetailsLink: '/agents/view-agent-details',
	agentsWorkflows: '/agents/workflows',
	agentsRoute: '/agents/route',
	marketingCampaigns: '/analytics/engagement/marketing-campaigns',
};
