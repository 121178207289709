import { Dispatch, SetStateAction } from 'react';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { toaster } from 'baseui/toast';

import Button from 'components/UI/ENGTButton/ENGTButton';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

import { CHANNEL_NAME_ICON_MAPPING, SPLIT_BROADCAST_COLORS, WHATSAPP_PLATFORMS } from 'shared/consts/consts';
import { IContactUploadFailedProps, IObjProps } from 'shared/consts/types';
import { BROADCAST_MESSAGE_TYPES, BROADCAST_TYPE, CHANNELS, IMPORT_CONTACT_STATUS } from 'shared/enum';
import { GTMTrackingIds } from 'shared/enum/trackingEnums';
import { isValidResponseObject, isValidResponseObjectHavingStatusCode } from 'shared/helpers';
import AddIcon from 'shared/icons/AddIcon';
import DropDownToggleDown from 'shared/icons/DropDownToggleDown';
import DropDownToggleUp from 'shared/icons/DropDownToggleUp';
import { sendButtonClickEventToGA4 } from 'shared/trackingHelpers';

import { contactsAPI } from 'store/App/Contacts/api';
import { API } from 'store/Broadcast/api';

import { i18nHelper as t } from 'i18nHelper';

export const getChannelTooltip = (channel: string, css: any) => (
	<ENGTTooltip content={CHANNEL_NAME_ICON_MAPPING[channel]['name']} ignoreBoundary>
		<ENGTImage
			lazy
			src={CHANNEL_NAME_ICON_MAPPING[channel]['icon']}
			alt={t('components:addAdmin.channelUrl')}
			className={css({
				height: '1.2rem',
				width: '1.2rem',
				marginRight: '0.4rem',
			})}
		/>
	</ENGTTooltip>
);

export const createBroadcast = (broadcasts: IObjProps, fetchAccountStatus: any, css: any) => (
	<Block alignItems='first baseline'>
		<Button
			size={SIZE.compact}
			kind={KIND.primary}
			label={t('pages:broadcast.basic.createNewBroadCast')}
			startEnhancer={<AddIcon />}
			className={css({
				display: broadcasts.data.length ? '' : 'none  ! important',
				marginBottom: '2rem !important',
			})}
			onClick={() => {
				fetchAccountStatus();
				sendButtonClickEventToGA4({ gtmTrackingId: GTMTrackingIds.CREATE_NEW_BROADCAST_BUTTON });
			}}
		/>
	</Block>
);

export const headerTypeOptions = [
	{
		name: 'Image',
		value: 'Image',
	},
	{
		name: 'Video',
		value: 'Video',
	},
	{
		name: 'Text',
		value: 'Text',
	},
	{
		name: 'Document',
		value: 'Document',
	},
];

export enum headerType {
	TEXT = 'TEXT',
	DOCUMENT = 'DOCUMENT',
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
}

export enum buttonType {
	QUICK_REPLY = 'QUICK_REPLY',
	CALL_TO_ACTION_URL = 'URL',
	CALL_TO_ACTION_PHONE_NUMBER = 'PHONE_NUMBER',
	COPY_CODE = 'COPY_CODE',
	OTP = 'OTP',
}

export enum AutoRetryHoverMessageKey {
	AUTO_RETRY_COMPLETE = 'autoRetryCompleteKey',
	SUBSEQUENT_AUTO_RETRY_SCHEDULED = 'subsequentAutoRetryScheduledKey',
	FIRST_AUTO_RETRY_SCHEDULED = 'firstAutoRetryScheduledKey',
	AUTO_RETRY_IN_PROGRESS = 'autoRetryInProgressKey',
}

export const getHtmlFormatText = (editorContent: string) => {
	const htmlFormat = [
		{ symbol: '*', tag: 'strong' },
		{ symbol: '_', tag: 'em' },
		{ symbol: '~', tag: 's' },
		{ symbol: '```', tag: 'pre' },
		{ symbol: '\n', tag: 'br' },
	];

	htmlFormat.forEach(({ symbol, tag }) => {
		if (tag === 'br') {
			return;
		}
		const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
		const matches = editorContent?.match(regex);
		if (!matches) {
			return editorContent;
		}

		matches?.forEach((match) => {
			let formatted = match;
			for (let i = 0; i < 2; i++) {
				formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
			}
			editorContent = editorContent.replace(match, formatted);
		});
	});

	return editorContent;
};

export const createNoDataTemplateFooter = (fetchAccountStatus: any, css: any) => (
	<Button
		size={SIZE.compact}
		kind={KIND.primary}
		label={t('pages:broadcast.basic.createNewBroadCast')}
		startEnhancer={<AddIcon />}
		className={css({
			marginTop: '3rem',
		})}
		onClick={fetchAccountStatus}
	/>
);

export const modifyChannels = (data: Array<string> | string, isBroadcastV2Enabled: boolean) => {
	const WHATSAPP_CHANNEL_PROVIDERS = [
		'whatsapp',
		'nexmo',
		'clickatell',
		'kaleyra',
		'twilio',
		'dialog360',
		'sms_clickatell',
		'airtel',
	];
	const filteredData = Array.isArray(data) ? data : new Array(data);

	return isBroadcastV2Enabled
		? filteredData.map((channel: string) => channel.toLowerCase())
		: filteredData.some((provider) => WHATSAPP_CHANNEL_PROVIDERS.includes(provider))
			? [CHANNELS.WHATSAPP]
			: filteredData;
};

export const formSelectValidation = (value: any) => {
	if (!value[0]?.id) {
		return t('errors:requiredErrorMessage') as string;
	}

	return true;
};

export const getQueryParamsForCreateBroadcast = (location: any) => {
	const getQueryParams = new URLSearchParams(location.search);
	const mode = getQueryParams.get('mode') || BROADCAST_TYPE.CREATE;
	const queryPayload = getQueryParams.get('payload') || '""';
	const triggeredPath = getQueryParams.get('path');
	const welcomeJourney = getQueryParams.get('welcomeAccJourney') === 'true';

	return {
		parsedList: queryPayload === null ? queryPayload : decodeURIComponent(queryPayload),
		queryPayload,
		mode,
		triggeredPath,
		welcomeJourney,
	};
};

export const filterAccordionCss = (theme: any, isEmpty: boolean) => ({
	style: {
		PanelContainer: {
			style: ({ $expanded }: any) => ({
				width: '15rem',
				color: theme.colors.primaryA,
				borderTopRightRadius: $expanded ? '0rem' : '0.3rem',
				borderTopLeftRadius: $expanded ? '0rem' : '0.3rem',
			}),
		},
		Header: {
			style: ({ $expanded }: any) => ({
				borderBottomRightRadius: $expanded ? '0rem' : '0.3rem',
				borderBottomLeftRadius: $expanded ? '0rem' : '0.3rem',
				borderTopLeftRadius: '0.3rem',
				borderTopRightRadius: '0.3rem',
				borderBottom: 'none',
				boxShadow: '0 0.125rem 0.125rem 0 #00000014',
				fontWeight: 300,
				fontSize: '0.875rem',
				paddingTop: '0.25rem',
				paddingBottom: '0.25rem',
				paddingLeft: '1rem',
				paddingRight: '0.25rem',
				color: isEmpty ? theme.colors.contentStateDisabled : theme.colors.primaryA,
			}),
		},
		Content: {
			style: ({ $theme }: any) => ({
				backgroundColor: $theme.colors.primaryB,
				borderBottomRightRadius: '0.3rem',
				borderBottomLeftRadius: '0.3rem',
				paddingTop: '0rem',
				paddingBottom: '0rem',
				zIndex: theme.colors.zIndex400,
			}),
		},
		ToggleIcon: {
			component: (props: any) => (
				<div style={{ marginRight: '0rem', display: 'flex' }}>
					{props.title === 'Collapse' ? <DropDownToggleDown /> : <DropDownToggleUp />}
				</div>
			),
		},
		Root: {
			style: () => ({
				borderTopWidth: '0rem',
			}),
		},
	},
});

export const containerCss = (theme: any) => ({
	position: 'relative',
	width: '100%',
	backgroundColor: theme.colors.primaryB,
	display: 'block',
	zIndex: '100',
	borderTop: `1px solid ${theme.modalCloseBgColor}`,
	alignItems: 'center',
});

export const channelLabelCss = (isSelected: boolean, theme: any) => ({
	paddingTop: '1rem',
	paddingBottom: '1rem',
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	paddingLeft: '1.25rem',
	paddingRight: '1.25rem',
	cursor: 'pointer',
	borderBottom: `1.25px solid ${theme.modalCloseBgColor}`,
	color: isSelected ? theme.colors.accent : theme.colors.primaryA,
	':hover': {
		background: theme.modalCloseBgColor,
	},
});

export const errorToaster = () => {
	toaster.negative(
		<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
		{}
	);
};

export enum GRAPH_TYPES {
	GROUPED = 'grouped',
	STACKED = 'stacked',
}

export enum CREDIT_CONSUMPTION_TYPE {
	whatsapp = 'WHATSAPP',
	all_channels = 'ALL_CHANNELS',
}

export const WA_ENGATI_COM_REDIRECT_LINK = 'wa-analytics.engati.com';

export const WA_CBOTS_LIVE_REDIRECT_LINK = 'wa-analytics.cbots.live';

export const WA_ENGATI_COM_REDIRECT_LINK_OLD = 'wa.engati.com';

export const checkIfValidUrl = (url: string, parameter?: string) =>
	url.includes(WA_ENGATI_COM_REDIRECT_LINK + (parameter ?? '')) ||
	url.includes(WA_CBOTS_LIVE_REDIRECT_LINK + (parameter ?? '')) ||
	url.includes(WA_ENGATI_COM_REDIRECT_LINK_OLD + (parameter ?? ''));

export const getChannel = (channels: string, configuredChannels: Array<string>, isBroadcastV2Enabled: boolean) => {
	const storedChannel = isBroadcastV2Enabled && channels ? channels.toLowerCase() : channels || CHANNELS.ALL_CHANNELS;
	const channel = configuredChannels.includes(storedChannel) ? storedChannel : '';

	return getChannelValueBasedOnVersion(channel, isBroadcastV2Enabled);
};

export const getChannelValueBasedOnVersion = (channel: string, isBroadcastV2Enabled: boolean) => {
	let channelValue;
	if (isBroadcastV2Enabled) {
		channelValue = channel === CHANNELS.ALL_CHANNELS ? null : channel.toUpperCase();
	} else if (channel === CHANNELS.WHATSAPP) {
		channelValue = WHATSAPP_PLATFORMS;
	} else if (channel === CHANNELS.ALL_CHANNELS) {
		channelValue = null;
	} else {
		channelValue = channel;
	}

	return channelValue;
};

export const isChannelsArray = (channels: Array<string> | string) => (Array.isArray(channels) ? channels[0] : channels);

const splitIdColorMap: any = {};

export const assignColorsForSplitBroadcast = (broadcastData: IObjProps[]) => {
	let usedColorIndex = 0;
	Array.from(new Set(broadcastData.map((data) => data.parentSplitId)))
		.filter((splitId) => !splitIdColorMap[splitId])
		.map((splitId) => {
			splitIdColorMap[splitId] = SPLIT_BROADCAST_COLORS[usedColorIndex];
			usedColorIndex = (usedColorIndex + 1) % SPLIT_BROADCAST_COLORS.length;
		});

	return splitIdColorMap;
};

export const splitBroadcastHoverText = (broadcastTitle: string, t: any) => {
	const delimiter = '_set';
	const index = broadcastTitle.lastIndexOf(delimiter);

	return (
		<>
			{t('pages:broadcast.basic.splitBroadcastTooltip1', {
				setNumber: index > -1 ? parseInt(broadcastTitle.substring(index + delimiter.length)) : 0,
			})}
			<br />
			{t('pages:broadcast.basic.splitBroadcastTooltip2', {
				title: index > -1 ? broadcastTitle.substring(0, index) : '',
			})}
		</>
	);
};

export const optimiseFetchedBroadcastDataForV1 = (responseObject: IObjProps) => {
	const {
		broadcastTitle,
		createdBy,
		audience,
		payload,
		status,
		broadcastSchedule = null,
		publishedOn,
		createdOn,
		disabled,
		isSplitBroadcast,
	} = responseObject;
	const { rule } = audience || {};
	const {
		contactMetadataId,
		segmentResponse,
		channels,
		recurringNotificationRule,
		retargetSegment,
		retargetedBroadcastId,
		isOptOut,
		isAutomaticRetry,
	} = rule || {};
	const { id: userSegmentId } = segmentResponse || {};
	const { content, type: payloadType } = payload || {};
	const { data, flowKey, template, type } = content[0] || [{}];
	const { message, options } = data || {};
	const { scheduledType, scheduledTo, scheduledFrom } = broadcastSchedule || {};

	const contentPayload = {
		messageText: message,
		flowKey,
		optionList: options,
		template,
	};

	const scheduleModel = {
		scheduledType,
		scheduledTo,
		scheduledFrom,
	};

	const contentType = () => {
		if (type === BROADCAST_MESSAGE_TYPES.OPTIONS) {
			return BROADCAST_MESSAGE_TYPES.OPTIONS;
		}

		return payloadType === BROADCAST_MESSAGE_TYPES.DIRECT || payloadType === BROADCAST_MESSAGE_TYPES.FLOW
			? payloadType
			: BROADCAST_MESSAGE_TYPES.TEMPLATE;
	};

	const broadcastData = {
		broadcastName: broadcastTitle,
		createdBy,
		contentType: contentType(),
		contentPayload,
		contactMetadataId,
		userSegmentId,
		channels,
		recurringNotificationRule,
		retargetSegment,
		retargetedBroadcastId,
		isOptOut,
		isAutomaticRetry,
		status,
		scheduleModel: broadcastSchedule ? scheduleModel : null,
		publishedOn,
		createdOn,
		disabled,
		isSplitBroadcast,
	};

	return broadcastData;
};

export const optimiseFetchedBroadcastDataForV2 = (responseObject: IObjProps) => {
	const {
		broadcastTitle,
		createdBy,
		targetGroupSelector,
		channel,
		contentType: type,
		contentPayload: payload,
		parentRetargetId,
		optOutEnabled,
		automaticRetryConfig,
		status,
		publishOn,
		createdOn,
		disabled,
		splitBroadcast,
		broadcastObjective,
		autoRetryAttempts,
		autoRetryInterval,
		broadcastRetryType,
	} = responseObject;
	const { contact_metadata_id, segment_id, recurring_notification_rule, retarget_rules } = targetGroupSelector || {};
	const { message_text, flow_key, options, template } = payload;

	const contentPayload = {
		messageText: message_text,
		flowKey: flow_key,
		optionList: options,
		template,
	};

	const contentType = () => {
		if (type === BROADCAST_MESSAGE_TYPES.MESSAGE_V2) {
			return BROADCAST_MESSAGE_TYPES.DIRECT;
		}

		return type === BROADCAST_MESSAGE_TYPES.MESSAGE_WITH_OPTIONS_V2 ? BROADCAST_MESSAGE_TYPES.OPTIONS : type;
	};

	const broadcastData = {
		broadcastName: broadcastTitle,
		createdBy,
		contentType: contentType(),
		contentPayload,
		contactMetadataId: contact_metadata_id,
		userSegmentId: segment_id,
		channels: [channel?.toLowerCase()],
		recurringNotificationRule: recurring_notification_rule,
		retargetSegment: retarget_rules,
		retargetedBroadcastId: parentRetargetId,
		isOptOut: optOutEnabled,
		isAutomaticRetry: automaticRetryConfig,
		status,
		scheduleModel: null,
		publishedOn: publishOn,
		createdOn,
		disabled,
		isSplitBroadcast: splitBroadcast,
		broadcastObjective,
		autoRetryAttempts,
		autoRetryInterval,
		broadcastRetryType,
	};

	return broadcastData;
};

export const getProgressBarStatusByMetaId = (
	contactListId: number,
	setPercentage: Dispatch<SetStateAction<number>>,
	successCallBack: () => void,
	errorCallback: () => void
) => {
	contactsAPI
		.getProgressBarStatus(contactListId)
		.then((response: IObjProps) => {
			if (isValidResponseObjectHavingStatusCode(response)) {
				const data = response.data.responseObject;
				setPercentage((data.recordProcessed / data.totalRecord) * 100);
				if (data.recordProcessed / data.totalRecord === 1) {
					successCallBack?.();
				}
			} else {
				errorCallback();
			}
		})
		.catch((error: any) => {
			errorCallback();
		});
};

export const isRetargetSegmentViaContactUploadFlow = (mode: string, isRetargetViaContactUpload: boolean) =>
	mode === BROADCAST_TYPE.CUSTOMER_SEGMENT && isRetargetViaContactUpload;

export const initiateContactImport = (
	platform: string,
	segmentId: string,
	timezone: string,
	getProgressBar: (contactListId: number, params: IObjProps) => void,
	params: IObjProps,
	toggleProgressBarModal: Dispatch<SetStateAction<boolean>>,
	setContactUploadFailed: Dispatch<SetStateAction<IContactUploadFailedProps>>
) => {
	API.initiateContactImport(platform, segmentId, timezone)
		.then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				const data = response.data.responseObject;
				getProgressBar(data, params);
			} else {
				const desc = response?.data?.desc || '';
				toggleProgressBarModal(false);
				if (desc === IMPORT_CONTACT_STATUS.FILE_SIZE_EXCEEDED) {
					setContactUploadFailed({
						showModal: true,
						status: t('pages:store.fileSizeExceededError'),
					});
				} else {
					setContactUploadFailed({ showModal: true });
				}
			}
		})
		.catch((error: any) => {
			toggleProgressBarModal(false);
			setContactUploadFailed({ showModal: true });
		});
};

export const EXTERNAL_BROADCAST_WITH_ANALYTICS = ['API_TRIGGER', 'WRAPPER_API'];

export enum EXTERNAL_BROADCAST_WORKFLOW {
	DOWNLOAD_BROADCAST_FAILED_USERS = 'DOWNLOAD_BROADCAST_FAILED_USERS',
	EXTERNAL_DOWNLOAD_BROADCAST_FAILED_USERS = 'EXTERNAL_DOWNLOAD_BROADCAST_FAILED_USERS',
	DOWNLOAD_BROADCAST_TOTAL_USERS = 'DOWNLOAD_BROADCAST_TOTAL_USERS',
	EXTERNAL_DOWNLOAD_BROADCAST_TOTAL_USERS = 'EXTERNAL_DOWNLOAD_BROADCAST_TOTAL_USERS',
	BROADCAST_ANALYTICS = 'BROADCAST_ANALYTICS',
	EXTERNAL_BROADCAST_ANALYTICS = 'EXTERNAL_BROADCAST_ANALYTICS',
}

export const isValidPercentIncrease = (percentIncrease: number) =>
	percentIncrease && percentIncrease !== 100 && percentIncrease !== -100;

export enum CONTACT_LIST_FIELDS {
	USER_GROUP = 'userGroup',
	SELECTED_CONTACT_LIST = 'selectedContactList',
}

export enum BROADCAST_FORM_FIELDS_IDS {
	SCHEDULE_TYPE = 'scheduledType',
	SELECTED_PLATFORMS = 'selectedPlatforms',
	MESSAGE_TYPE = 'messageType',
	BROADCAST_NOTIFICATION = 'broadcastNotification',
	SCHEDULE_BROADCAST = 'scheduleBroadcast',
	SELECTED_SEGMENT_ID = 'selectedSegmentId',
	OPTIONS = 'options',
	BROADCAST_TITLE = 'broadcastTitle',
	TEMPLATE_NAME = 'templateName',
	FILE_URL = 'fileUrl',
	FILE_URL_DOC = 'fileUrlDoc',
	MEDIA_DOC = 'mediaDoc',
	TEMPLATE_TITLE = 'templateTitle',
	SCHEDULE_FROM = 'scheduleFrom',
	SCHEDULE_TO = 'scheduleTo',
	HEADER_TYPE = 'headerType',
	VIDEO = 'video',
	EXPIRATION_DATE_TIME = 'expirationDateTime',
	OFFER_CODE = 'offerCode',
	PATH = 'path',
	TOPIC = 'topic',
	HEADER_PARAM = 'headerParam',
	BODY_PARAM = 'bodyParam',
	LANGUAGE = 'language',
	MESSAGES = 'messages',
	FLOW_KEY = 'flowKey',
	BUTTONS = 'buttons',
	TARGET_WHATSAPP_NUMBER = 'targetWhatsAppNumber',
	LANGUAGE_CODE = 'languageCode',
	RCS_IMAGE_URL = 'rcsImageUrl',
	RCS_HEADER_TEXT = 'rcsHeaderText',
	RCS_BUTTON_LABEL = 'rcsButtonLabel',
	RCS_REDIRECT_URL = 'rcsRedirectUrl',
	SELECTED_OBJECTIVE = 'selectedObjective',
	FLOW_ACTION_DATA = 'flowActionData',
}
