import React from 'react';
import { useStyletron } from 'baseui';
import { useFormContext } from 'react-hook-form';

import Textarea from 'components/UI/ENGTTextarea/ENGTTextarea';

import { getErrorMessage } from 'shared/helpers';

import FormLabel from '../FormLabel/FormLabel';

export interface IFormTextareaProps {
	defaultValue?: any;
	errorCondition?: string;
	inputRef?: any;
	label?: string;
	modal?: boolean;
	name: string;
	description?: any;
	tooltip?: string | React.ReactElement;
	[key: string]: any;
	isDisabled?: boolean;
	dataQa?: string;
	labelSize?: string;
	sanitizeInput?: boolean;
	onChange: (...args: any[]) => any;
}

export const FormTextarea = ({
	defaultValue,
	errorCondition,
	label,
	modal,
	inputRef,
	name,
	placeholder,
	tooltip,
	isDisabled,
	dataQa,
	onChange,
	description,
	labelSize,
	sanitizeInput = true,
	...inputProps
}: IFormTextareaProps) => {
	const [css, theme] = useStyletron();

	const { errors } = useFormContext();

	const errorMsg = getErrorMessage(name, errors, true) ? getErrorMessage(name, errors, true) : errors[name];

	return (
		<div className={css({ textAlign: 'left' })}>
			{label && (
				<FormLabel id={name} label={label} tooltip={tooltip} modal={modal || false} fontSize={labelSize} />
			)}
			{description && (
				<div
					className={css({
						color: theme.colors.primaryA,
						fontSize: '0.75rem',
					})}
				>
					{description}
				</div>
			)}
			<Textarea
				id={name}
				inputRef={inputRef}
				name={name}
				placeholder={placeholder}
				autoComplete='off'
				error={errorMsg}
				disabled={isDisabled}
				onChange={onChange}
				className={css({ marginBottom: '1rem' })}
				dataQa={dataQa}
				sanitizeInput={sanitizeInput}
				{...inputProps}
			/>
		</div>
	);
};
