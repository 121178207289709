import React, { useState } from 'react';
import { useStyletron } from 'baseui';

import { LabelWithTooltip } from 'components/UI/LabelWithToolTip/LabelWithTooltip';

import { IObjProps } from 'shared/consts/types';

import Loader from '../Loader/Loader';

export interface IENGTMenuItemProps {
	label: string;
	Icon?: any;
	dataQa?: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	iconCss?: IObjProps;
}

export const ENGTMenuItem: React.FC<React.PropsWithChildren<IENGTMenuItemProps>> = ({
	Icon,
	label,
	dataQa,
	iconCss,
	isLoading,
	isDisabled,
}) => {
	const [css, theme] = useStyletron();
	const [onHover, setHoverEvent] = useState(false);

	const handleMouseOver = () => setHoverEvent(true);
	const handleMouseOut = () => setHoverEvent(false);

	return isLoading ? (
		<div className={css({ paddingTop: '0.375rem', paddingBottom: '0.375rem' })}>
			<Loader loaderBlockHeight='8%' size={28} />
		</div>
	) : (
		<div
			className={css({
				paddingTop: '6px',
				paddingRight: '6px',
				paddingBottom: '6px',
				paddingLeft: '6px',
				borderBottomRightRadius: theme.borders.radius200,
				borderBottomLeftRadius: theme.borders.radius200,
				borderTopRightRadius: theme.borders.radius200,
				borderTopLeftRadius: theme.borders.radius200,
				display: 'flex',
				justifyContent: 'left',
				alignItems: 'center',
				':hover': {
					background: isDisabled ? '' : theme.colors.accent300,
				},
				height: '1.75rem',
			})}
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
		>
			{Icon &&
				(iconCss ? (
					<div className={css(iconCss)}>
						<Icon fill={onHover ? theme.colors.accent : theme.colors.accent50} size={24} />
					</div>
				) : (
					<Icon fill={onHover ? theme.colors.accent : theme.colors.accent50} size={24} />
				))}
			<div className={css({ paddingTop: '0.25rem' })}>
				<LabelWithTooltip
					fontSize='0.875rem'
					fontWeight='normal'
					lineHeight='1rem'
					color={
						isDisabled
							? `${theme.colors.contentStateDisabled} !important`
							: onHover
								? `${theme.colors.accent} !important`
								: `${theme.colors.accent50} !important`
					}
					dataQa={dataQa}
				>
					{label}
				</LabelWithTooltip>
			</div>
		</div>
	);
};

export default ENGTMenuItem;
