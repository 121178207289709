import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Overflow } from 'baseui/icon';
import { toaster } from 'baseui/toast';
import { LabelLarge, ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import Layout, { LayoutContent, LayoutHeader } from 'components/Common/Layout';
import ContactSalesModal from 'components/ContactModal/ContactModal';
import CreateClampaignBtn from 'components/shared/CreateCampaignBtn/CreateCampaignBtn';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import usePaginationHook from 'components/UI/ENGTPagination/PaginationHook';
import ENGTSpacer from 'components/UI/ENGTSpacer/ENGTSpacer';
import ENGTTable from 'components/UI/ENGTTable/ENGTTable';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';
import PageHeader from 'components/UI/PageHeader/PageHeader';
import PageHeaderWithSections, { PageHeaderSection } from 'components/UI/PageHeader/PageHeaderWithSections';

import { PRICING_PAGE_URL } from 'shared/consts/consts';
import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';
import { IObjProps } from 'shared/consts/types';
import { isEmpty } from 'shared/helpers';
import NoCampaign from 'shared/icons/Broadcast/NoCampaign.svg';
import BroadcastIcon from 'shared/icons/BroadcastIcon.svg';

import {
	isAcceleratorEcomSelector,
	isShopifyEnabledAcc,
	isShopifyPrivateAcc,
	marketingCampaignsAnalyticsSelector,
} from 'store/App/User/selectors';
import { botRefSelector } from 'store/Bot/selectors';
import { getAnalyticsCampaignAction, getCampaignAction } from 'store/Campaign';
import { campaignsData } from 'store/Campaign/selectors';
import { downloadCampaignUsersDataAction } from 'store/Download/actions';
import { RootState } from 'store/rootReducer';
import { createdOnSelector, customerIdSelector, isEngatiBrandSelector, userId } from 'store/Users/selectors';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

import CampaignDetailsModal from './components/CampaignDetailsModal';
import DeleteCampaignModal from './components/DeleteCampaignModal';
import useIsCampaignAnalyticsEnabledHook from './hooks/useIsCampaignAnalyticsEnabledHook';

const { campaignAnalyticsSelected } = ThreeColumnRouteLinks;

function CampaignsV2({
	campaignModal,
	toggleCampaignModal,
	isEdit,
	setIsEdit,
	selectedCampaign,
	setSelectedCampaign,
}: {
	campaignModal: boolean;
	toggleCampaignModal: Function;
	isEdit: boolean;
	setIsEdit: Function;
	selectedCampaign: IObjProps;
	setSelectedCampaign: Function;
}) {
	const [css, theme]: [any, any] = useStyletron();
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();
	const { t } = useTranslation(['common', 'pages']);

	const [isDeleteModalOpen, toggleDeleteModal] = useState<boolean>(false);
	const [contactSales, setContactSales] = useState<boolean>(false);

	const campaigns = useSelector(campaignsData);
	const botMode = useSelector((state: RootState) => state.Bot.data.mode);
	const isCampaignsEnabled: boolean = useSelector((state: RootState) => state.Bot.configData.enable_campaigns);
	const disableAccessToPathBuilder = useSelector(
		(state: RootState) => state.User.botAdmin.data.disable_access_to_path_builder
	);
	const isAdminMode = useSelector((state: RootState) => state.User.botAdmin.data.is_admin_mode);
	const enableShopifyPrivateApp: any = useSelector(isShopifyPrivateAcc);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const uid = useSelector(userId);
	const botRef = useSelector(botRefSelector);
	const cid = useSelector(customerIdSelector);
	const isAcceleratorEcomEnabled = useSelector(isAcceleratorEcomSelector);

	const { data: campaignList, pagination: campaignPagination, isLoading } = campaigns;
	const { size, page, totalPages, totalElements } = campaignPagination;

	const showWhatsappCampaignAnalytics = useIsCampaignAnalyticsEnabledHook();

	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const isShopifyPublicAccount = !enableShopifyPrivateApp && isShopifyEnabled;
	const createdDate = useSelector(createdOnSelector);
	const AnalyticsTrackingStartDate = 1690848033000;

	const createCampaign = !marketingCampaignsAnalyticsType ? (
		<CreateClampaignBtn
			onClick={() => {
				setSelectedCampaign({});
				toggleCampaignModal(true);
				setIsEdit(false);
			}}
		/>
	) : (
		<></>
	);

	const downloadFileToaster = () => {
		toaster.info(<ENGTToasterContainer title={t('common:success')} description={t('common:downloadInfo')} />, {});
	};

	const campaignColumns = [
		{
			id: 'campaign_name',
			name: t('pages:broadcast.campaigns.campaignName'),
			processor: (data: any) => parse(data.toLocaleString()),
		},
		{
			id: 'subscribers',
			name: t('pages:broadcast.campaigns.subscribers'),
			processor: (data: any, row: any) =>
				data > 0 ? (
					<NavLink
						className={css({
							color: theme.colors.primaryA,
						})}
						to='/broadcast/campaigns'
						onClick={() => {
							const payload = {
								customerId: cid,
								userId: uid,
								botRef,
								campaignId: row.campaign_id,
								workflow: 'DOWNLOAD_CAMPAIGN_SUBSCRIBERS',
								fileName: `${row.campaign_name}_campaign_users.csv`,
							};
							dispatch(downloadCampaignUsersDataAction(payload));
							downloadFileToaster();
						}}
					>
						{data.toLocaleString()}
					</NavLink>
				) : (
					data.toLocaleString()
				),
		},
		{
			id: 'messages',
			name: t('pages:broadcast.campaigns.messages'),
			processor: (data: any) => data.toLocaleString(),
		},
	];

	const knowMore = isEngatiBrand ? (
		<span
			className={css({
				color: theme.colors.primaryA,
			})}
		>
			<a
				style={{ textDecoration: 'none', color: theme.colors.accent }}
				href={DOC_LINKS_V2.broadcast.campaigns}
				target='_blank'
				rel='noreferrer'
			>
				{t('pages:broadcast.campaigns.clickHere')}
			</a>{' '}
			{t('pages:broadcast.campaigns.learnCampaigns')}
		</span>
	) : (
		<></>
	);

	const viewCampaign = {
		label: t('pages:broadcast.campaigns.viewCampaign'),
		onClick: (data: any) =>
			navigate(`${`${campaignAnalyticsSelected}?id=`}${encodeURIComponent(data.campaign_id)}`),
	};

	const renameCampaign = {
		label: t('pages:broadcast.campaigns.renameCampaign'),
		onClick: (data: any) => {
			setSelectedCampaign(data);
			toggleCampaignModal(true);
			setIsEdit(true);
		},
	};

	const deleteCampaign = {
		label: t('pages:broadcast.campaigns.deleteCampaign'),
		onClick: (data: any) => {
			setSelectedCampaign(data);
			toggleDeleteModal(true);
		},
	};

	const getRowActions = () => {
		const actions = {
			primary: [viewCampaign, renameCampaign, deleteCampaign],
			ecommAdmin: [viewCampaign],
			ecomm: [viewCampaign],
		};

		if (!isAcceleratorEcomEnabled) {
			return actions.primary;
		}
		if (isAcceleratorEcomEnabled && isAdminMode) {
			return actions.ecommAdmin;
		}

		return actions.ecomm;
	};

	const rowActions = [
		{
			items: getRowActions(),
			onClick: () => {},
			icon: <Overflow size={24} />,
		},
	];

	const renderRowActions = () => {
		if (showWhatsappCampaignAnalytics) {
			rowActions[0].items.push({
				label: t('pages:broadcast.campaigns.exportSubscribers'),
				onClick: (data: any) => {
					const payload = {
						customerId: cid,
						userId: uid,
						botRef,
						campaignId: data.campaign_id,
						workflow: 'DOWNLOAD_CAMPAIGN_SUBSCRIBERS_V2',
						fileName: `${
							showWhatsappCampaignAnalytics ? data.campaignName : data.campaign_name
						}_campaign_users.csv`,
					};
					dispatch(downloadCampaignUsersDataAction(payload));
					downloadFileToaster();
				},
			});
		}

		return rowActions;
	};

	const getWhatsappAnalyticsCampaignList = () => {
		if (showWhatsappCampaignAnalytics) {
			return campaignList[0]?.campaignLevelData ? campaignList : [];
		}

		return !showWhatsappCampaignAnalytics && campaignList[0]?.campaignLevelData ? [] : campaignList;
	};

	useEffect(() => {
		showWhatsappCampaignAnalytics ? onAnalyticsPaginationChange() : onPaginationChange();
	}, []);

	useEffect(() => {
		setPagination({
			size,
			page,
			totalPages,
			totalElements,
		});
	}, [campaignPagination]);

	const onPaginationChange = (modifiedPage = page, modifiedSize = paginationSize || size) => {
		const params: IObjProps = {
			params: {
				page: (modifiedPage || 1) - 1,
				size: modifiedSize,
			},
			target_url: 'campaign',
		};

		dispatch(getCampaignAction(params));
	};

	const onAnalyticsPaginationChange = (modifiedPage = page, modifiedSize = paginationSize || size) => {
		const params: IObjProps = {
			page: (modifiedPage || 1) - 1,
			size: modifiedSize,
			targetApi: 'campaignAnalytics',
		};

		dispatch(getAnalyticsCampaignAction(params));
	};

	const paginationMethods = usePaginationHook({
		onChange: showWhatsappCampaignAnalytics ? onAnalyticsPaginationChange : onPaginationChange,
	});

	const { pagination, setPagination } = paginationMethods;

	const { size: paginationSize } = pagination;

	const entitlementBasedSubHeading = (
		<span
			className={css({
				color: theme.colors.primaryA,
			})}
		>
			{t('common:please')}
			<a
				className={css({ color: theme.colors.accent, textDecoration: 'underline' })}
				onClick={() => setContactSales(true)}
			>
				{t('common:contactUs')}
			</a>
			{campaignList?.length
				? t('pages:broadcast.campaigns.addMoreCampaigns')
				: t('pages:broadcast.campaigns.addCampaigns')}
		</span>
	);

	const navigateToPricingPage = () => {
		navigate(PRICING_PAGE_URL);
	};

	const showUpgradeBanner = () => (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				height: '100%',
			})}
		>
			<ENGTImage lazy src={BroadcastIcon} alt={t('pages:broadcast.campaigns.broadcastIconAltText')} />
			<LabelLarge
				className={css({
					fontWeight: 'bold',
					fontSize: '1.3rem',
					width: '25rem',
					textAlign: 'center',
					marginTop: '2rem',
				})}
			>
				{t('pages:broadcast.campaigns.campaignsNotEnabled')}
			</LabelLarge>
			{knowMore}
			{!isShopifyPublicAccount && (
				<ENGTSpacer>
					<ENGTButton label={t('common:upgrade')} onClick={navigateToPricingPage} />
				</ENGTSpacer>
			)}
		</div>
	);
	const analyticsCampaignColumns = [
		{
			id: 'campaignName',
			span: 2,
			name: t('pages:broadcast.campaigns.campaignsName'),
			processor: (data: any) => parse(data?.toLocaleString()),
		},
		{
			id: 'subscribersCount',
			name: t('pages:broadcast.campaigns.subscribers'),
			processor: (data: any) => data?.toLocaleString() ?? '-',
			tooltip: t('pages:broadcast.campaigns.subscribersTooltip'),
		},
		{
			id: 'sentCount',
			name: t('pages:broadcast.campaigns.sent'),
			processor: (data: any) => data?.toLocaleString(),
			tooltip: t('pages:broadcast.campaigns.sentTooltip'),
		},
		{
			id: 'deliveredCount',
			name: t('pages:broadcast.campaigns.delivered'),
			processor: (data: any) => data?.toLocaleString(),
			tooltip: t('pages:broadcast.campaigns.deliveredTooltip'),
		},
		{
			id: 'readCount',
			name: t('pages:broadcast.campaigns.read'),
			processor: (data: any) => data?.toLocaleString(),
			tooltip: t('pages:broadcast.campaigns.readTooltip'),
		},
		{
			id: 'clickCount',
			name: t('pages:broadcast.campaigns.clickCount'),
			processor: (data: any) => data?.toLocaleString(),
			tooltip: t('pages:broadcast.campaigns.clickCountTooltip'),
		},
		{
			id: 'failedCount',
			name: t('pages:broadcast.campaigns.failed'),
			processor: (data: any) => data?.toLocaleString(),
			tooltip: t('pages:broadcast.campaigns.failedTooltip'),
		},
		{
			id: 'unsubscribersCount',
			name: t('pages:broadcast.campaigns.unsubscribers'),
			processor: (data: any) => data?.toLocaleString() ?? '-',
			tooltip: t('pages:broadcast.campaigns.unsubscribersTooltip'),
		},
	];

	const whatsappCampaignAnalyticsNotes = (isNote1Visible: boolean) => (
		<li>
			<ParagraphXSmall
				marginTop='0rem'
				marginBottom='0.2rem'
				color={theme.colors.primaryA}
				overrides={{
					Block: {
						style: {
							opacity: 0.8,
							whiteSpace: 'pre-wrap',
						},
					},
				}}
			>
				{isNote1Visible ? (
					<>{t('pages:broadcast.campaigns.note1')}</>
				) : (
					<>
						{t('pages:broadcast.campaigns.note2')}
						<b>{t('pages:broadcast.campaigns.whatsappAnalyticsStartDate')}</b>
					</>
				)}
			</ParagraphXSmall>
		</li>
	);

	const campaignBody = () => (
		<>
			<LayoutHeader>
				<PageHeaderWithSections>
					<PageHeader
						heading={!marketingCampaignsAnalyticsType && t('pages:broadcast.campaigns.title')}
						headerWrapperOverrides={
							marketingCampaignsAnalyticsType && {
								marginTop: '0rem !important',
							}
						}
						overrides={marketingCampaignsAnalyticsType && { marginBottom: '0rem' }}
						subHeading={
							disableAccessToPathBuilder && !isAdminMode
								? campaignList?.length
									? entitlementBasedSubHeading
									: ''
								: ''
						}
					/>

					<PageHeaderSection
						overrides={marketingCampaignsAnalyticsType && { marginBottom: '0rem !important' }}
					>
						{(!disableAccessToPathBuilder || isAdminMode) &&
							!isEmpty(campaignList) &&
							isCampaignsEnabled &&
							(!isAcceleratorEcomEnabled || (isAcceleratorEcomEnabled && isAdminMode)) &&
							createCampaign}
					</PageHeaderSection>
				</PageHeaderWithSections>
				{showWhatsappCampaignAnalytics && campaignList?.length > 0 && (
					<div className={css({ textAlign: 'left' })}>
						<ParagraphSmall marginTop='0.5rem' marginBottom='0rem' className={css({ fontWeight: 500 })}>
							{t('common:notes')}
						</ParagraphSmall>
						<ul className={css({ marginTop: '0.2rem' })}>
							{whatsappCampaignAnalyticsNotes(true)}

							{AnalyticsTrackingStartDate >= createdDate && whatsappCampaignAnalyticsNotes(false)}
						</ul>
					</div>
				)}
			</LayoutHeader>
			<LayoutContent>
				{isCampaignsEnabled ? (
					<ENGTTable
						loader={isLoading || !campaignList}
						columns={showWhatsappCampaignAnalytics ? analyticsCampaignColumns : campaignColumns}
						rows={getWhatsappAnalyticsCampaignList() || []}
						pagination={paginationMethods}
						paginationType='footer'
						rowActions={renderRowActions()}
						botMode={botMode}
						accordionRow={showWhatsappCampaignAnalytics}
						emptyDataView={
							<NoDataTemplate
								bannerImage={{
									altText: 'no-campaign',
									imageUrl: NoCampaign,
								}}
								imageCss={{ marginBottom: '1rem' }}
								className={css({
									marginTop: '3rem',
								})}
								heading={t('pages:broadcast.campaigns.noCampaignsHeading')}
								headingCss={{ marginTop: '1rem', marginBottom: '1rem' }}
								footer={disableAccessToPathBuilder && !isAdminMode ? <></> : createCampaign}
							>
								{disableAccessToPathBuilder && !isAdminMode ? entitlementBasedSubHeading : knowMore}
							</NoDataTemplate>
						}
						overrides={{
							wrapper: {
								marginLeft: 0,
								marginRight: 0,
							},
							Grid: {
								paddingRight: showWhatsappCampaignAnalytics ? '3.2rem' : '0.5rem',
							},
						}}
					/>
				) : (
					showUpgradeBanner()
				)}
				{campaignModal && (
					<CampaignDetailsModal
						isEdit={isEdit}
						toggleCampaignModal={toggleCampaignModal}
						selectedCampaign={selectedCampaign}
						setSelectedCampaign={setSelectedCampaign}
					/>
				)}
				{isDeleteModalOpen && (
					<DeleteCampaignModal toggleDeleteModal={toggleDeleteModal} selectedCampaign={selectedCampaign} />
				)}
			</LayoutContent>
		</>
	);

	return (
		<>
			{marketingCampaignsAnalyticsType ? (
				campaignBody()
			) : (
				<Layout content={{ span: 11 }}> {campaignBody()}</Layout>
			)}
			{contactSales && (
				<ContactSalesModal
					isModalOpen
					toggleModal={setContactSales}
					options={{ preSubject: '', contactMode: 'contact' }}
					heading={t('common:contact')}
					usage='campaign'
				/>
			)}
		</>
	);
}

export default CampaignsV2;
