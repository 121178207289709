import { serialize } from 'shared/helpers';
import { REACT_APP_API_GATEWAY_BASE_URL } from 'shared/config/config';
import BaseService from 'services/baseService';

const genericProxyRequestUrl = new BaseService('/genericProxyRequest/');
const genericProxyRequestUrlV2 = new BaseService('/v2/genericProxyRequest/');
const getColumnsUrl = new BaseService('/api/v2/columns');
const getExportHistoryUrl = new BaseService('/api/v1/export/history/');
const uploadApi = new BaseService('/v2/importUserList/', {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
});
const apiGatetWayGeneralProxyApi = new BaseService(REACT_APP_API_GATEWAY_BASE_URL);

export const usersAPI = {
	getColumns(params) {
		return getColumnsUrl.getRequestWithParams(params);
	},

	getUsers(params) {
		return genericProxyRequestUrlV2.getRequestWithParams(params);
	},

	getCategory(params) {
		return genericProxyRequestUrlV2.getRequestWithParams(params);
	},

	getExportHistory(params) {
		return getExportHistoryUrl.getRequestWithParams(params);
	},

	getGlobalUserProfile(params) {
		return genericProxyRequestUrlV2.getRequestWithParams(params);
	},

	getFlowAttributeValues(params) {
		return genericProxyRequestUrl.getRequest(serialize(params, '?'));
	},

	getUserConversationTrends(params) {
		return genericProxyRequestUrlV2.getRequestWithParams(params);
	},

	getGlobalUserConversations(params, body) {
		return genericProxyRequestUrlV2.postRequest(body, serialize(params, '?'));
	},

	getUserMessages(params, data) {
		return apiGatetWayGeneralProxyApi.postRequest(data, serialize(params, '?'));
	},

	startConversation(params, data) {
		return genericProxyRequestUrlV2.postRequest(data, serialize(params, '?'));
	},

	getUserSegments(page, size) {
		return genericProxyRequestUrl.getRequestWithParams({
			target_url: 'getAllSegments',
			params: JSON.stringify({
				page,
				size,
				sort: 'updatedOn,desc',
			}),
		});
	},

	createUserSegment(payload) {
		return genericProxyRequestUrl.putRequest({
			target_url: 'addEditSegment',
			...payload,
		});
	},

	deleteUserSegment(segmentId) {
		return genericProxyRequestUrl.deleteRequest(
			serialize(
				{
					target_url: 'deleteSegment',
					params: {
						segmentId,
					},
				},
				'?'
			)
		);
	},

	getImportHistory(page = 0, size = 25) {
		const params = {
			targetApi: 'importHistory',
			page,
			size,
		};

		return genericProxyRequestUrlV2.getRequestWithParams(params);
	},

	downloadImportHistory(params) {
		return genericProxyRequestUrlV2.getRequestWithParams(params);
	},

	uploadImportHistory(file, params) {
		const formData = new FormData();
		formData.append('file', file);

		return uploadApi.postRequest(formData, serialize(params, '?'));
	},

	fetchUserConversationTrends(userId) {
		return genericProxyRequestUrlV2.getRequestWithParams({
			userIds: userId,
			targetApi: 'getUserConversationTrends',
		});
	},

	getConversationHistory(params) {
		return genericProxyRequestUrlV2.getRequestWithParams(params);
	},

	unBlockUser(params) {
		const url = `${genericProxyRequestUrlV2.url}${serialize(params, '?')}`;
		const blockUserApi = new BaseService(url);

		return blockUserApi.putRequest();
	},

	getConversationLogsHistory(conversationId, userId, platform) {
		const params = {
			conversationId,
			userId,
			platform,
			targetApi: 'getConversationLogsHistory',
		};

		return genericProxyRequestUrlV2.getRequest(serialize(params, '?'));
	},
};
