import { useStyletron } from 'baseui';

import { ENGTMenuItem } from 'components/UI/ENGTMenuItem/ENGTMenuItem';

import { IObjProps } from 'shared/consts/types';

export interface IENGTMenuListProps {
	Items: Array<any>;
	isOpen: boolean;
	width?: string;
	right?: string;
	left?: string;
	top?: string;
	listItemCss?: IObjProps;
	iconCss?: IObjProps;
	marginBottom?: string;
	marginTop?: string;
	position?: 'absolute' | 'relative';
	stopPropagation?: boolean;
	zIndex?: number;
}

function ENGTMenuList({
	Items,
	isOpen,
	width,
	top,
	zIndex,
	listItemCss,
	iconCss,
	marginBottom,
	marginTop,
	position = 'absolute',
	stopPropagation = true,
	right,
	left,
}: IENGTMenuListProps) {
	const [css, theme]: [any, any] = useStyletron();

	const stopClick = (event: any) => {
		stopPropagation && event.stopPropagation();
	};

	return (
		<>
			{isOpen && (
				<ul
					className={css({
						position,
						width,
						zIndex,
						top,
						marginBottom,
						marginTop,
						paddingTop: '6px',
						paddingRight: '6px',
						paddingBottom: '6px',
						paddingLeft: '6px',
						background: theme.colors.inputFillPrimary,
						boxShadow: theme.lighting.shadow400,
						borderBottomRightRadius: theme.borders.radius300,
						borderBottomLeftRadius: theme.borders.radius300,
						borderTopRightRadius: theme.borders.radius300,
						borderTopLeftRadius: theme.borders.radius300,
						listStyleType: 'none',
						cursor: 'pointer',
						right,
						left,
					})}
					onClick={stopClick}
				>
					{Items.map((item, index) => (
						<li
							key={index}
							onClick={item.isDisabled ? '' : item.onClick}
							className={css({
								display: item?.notVisible ? 'none' : 'block',
								...listItemCss,
							})}
						>
							<ENGTMenuItem
								label={item.label}
								iconCss={iconCss}
								Icon={item.icon ? item.icon : false}
								isDisabled={item?.isDisabled}
							/>
						</li>
					))}
				</ul>
			)}
		</>
	);
}

ENGTMenuList.defaultProps = {
	width: 'fit-content',
	top: 'revert',
	listItemCss: {},
	marginTop: 'revert',
	zIndex: 1,
	right: null,
	left: null,
};

export default ENGTMenuList;
