import { DEFAULT_FILTER_CONSTANT, MESSAGE_LIST_MAXIMUM_CHARACTERS, MESSAGE_SENDER_MAP } from 'shared/consts/consts';
import { CHANNELS } from 'shared/enum/enum';
import { SHORTCUT_QUICK_FILTER, SLA_FILTER, SORTING } from 'shared/enum/messagesEnum';
import { getConversationMessageDuration, isEmptyString } from 'shared/helpers';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

import { i18nHelper } from 'i18nHelper';

import { allTabStatusFields, lastMessageFields } from 'pages/Messages/components/MessageFilterLite';
import {
	getIsValidLocationMessage,
	getLocationMessageHTML,
} from 'pages/Messages/MessageOverview/helpers/locationMessageHelper';

export const getLastMessageData = (data: any, conversationDuration: string) => {
	if (data?.content) {
		if (data.content === '-') {
			return i18nHelper('common:noMessages90Days', {
				conversationDuration: getConversationMessageDuration(conversationDuration, i18nHelper),
			});
		}
		if (typeof data.content === 'string') {
			const locationMessage: string = getLocationMessageHTML(data.content, MESSAGE_SENDER_MAP.user);
			const isValidLocationMessage: boolean = getIsValidLocationMessage(locationMessage);

			return isValidLocationMessage ? locationMessage : data.content;
		}
		if (typeof data.content === 'number') {
			return data.content;
		}
		if (data.content?.type === 'ContactCardPacket') {
			return i18nHelper('components:messagesPreview.contactShared');
		}
		if (typeof data.content === 'object') {
			if (data.content?.type === 'CommentPacket') {
				return i18nHelper('components:messagesPreview.commentPacket');
			}
			if (data.content?.html_content) {
				return data.content.html_content;
			}
			if (data?.content?.template) {
				const bodyComponent = data.content.template?.components?.filter(
					(component: { type: string }) => component?.type === 'BODY'
				);
				if (bodyComponent?.length && bodyComponent[0]?.text) {
					return bodyComponent[0].text;
				}
			}

			return i18nHelper('components:messagesPreview.templateNotification');
		}

		return '-';
	}

	return '-';
};

export const highlightSearchedMessage = (message: any, tagsSearchQuery: string, color: any) => {
	message = message?.substring(0, 150);
	const position = message?.toLowerCase().indexOf(tagsSearchQuery.toLowerCase());
	let result = message;
	if (position > -1) {
		result = `${message.substring(0, position)}<b>${message?.substring(
			position,
			position + tagsSearchQuery.length
		)}</b>${message?.substring(position + tagsSearchQuery.length, message.length)}`;
	}

	if (message?.length > MESSAGE_LIST_MAXIMUM_CHARACTERS) {
		result = `${result} . . .`;
	}

	return result;
};

export const searchInputCss = (isMessageSearchEnabled: any, theme: any) => ({
	Root: {
		borderTopRightRadius: isMessageSearchEnabled ? '0rem' : '0.625rem !important',
		borderTopLeftRadius: isMessageSearchEnabled ? '0rem' : '0.625rem !important',
		borderBottomRightRadius: isMessageSearchEnabled ? '0rem' : '0.625rem !important',
		borderBottomLeftRadius: isMessageSearchEnabled ? '0rem' : '0.625rem !important',
		backgroundColor: theme.colors.primaryB,
		borderTopWidth: '0.031rem',
		borderBottomWidth: '0.031rem',
		borderLeftWidth: '0.031rem',
		borderRightWidth: '0.031rem',
		borderStyle: 'solid',
		borderColor: theme.colors.contentStateDisabled,
		height: '2.5rem',
	},
	Input: {
		paddingLeft: '0rem',
		backgroundColor: theme.colors.primaryB,
	},
	InputContainer: {
		fontSize: '0.75rem',
		backgroundColor: theme.colors.primaryB,
	},
	StartEnhancer: {
		backgroundColor: theme.colors.primaryB,
	},
	EndEnhancer: {
		backgroundColor: theme.colors.primaryB,
	},
});

export const neutralScreenRedirection = (activeTab?: any) => {
	const url = activeTab
		? TwoColumnRouteLinks.messagingTab.replace(':tab', activeTab)
		: TwoColumnRouteLinks.simplifiedMessages;

	return url;
};

export const getUserConversationUrl = (cid: string, activeTab?: string) => {
	if (activeTab) {
		return `${TwoColumnRouteLinks.messagingTab.replace(
			':tab',
			activeTab
		)}/${TwoColumnRouteLinks.messagingConverastionTab.replace(':cid', cid)}`;
	}

	return `${TwoColumnRouteLinks.simplifiedMessages}/${TwoColumnRouteLinks.simplifiedMessagesConversation.replace(
		':cid',
		cid
	)}`;
};

export const renderWindowClosingTime = (windowclosingTime: any) => {
	if (windowclosingTime) {
		const diffInMs = new Date(windowclosingTime).getTime() - new Date().getTime();
		let message = '';
		if (diffInMs > 0) {
			const minutes = Math.floor(diffInMs / (1000 * 60));
			const hours = Math.floor(minutes / 60);
			if (hours) {
				message = `${hours} Hour(s) Left!`;
			} else if (minutes >= 45 && minutes < 60) {
				message = '45 mins Left!';
			} else if (minutes >= 30 && minutes < 45) {
				message = '30 mins Left!';
			} else if (minutes >= 15 && minutes < 30) {
				message = '15 mins Left!';
			} else if (minutes >= -1 && minutes < 15) {
				message = `< ${minutes === 0 || minutes === -1 ? 1 : minutes} mins Left!`;
			}
		}

		return message;
	}
};

export const showWindowLimit = (windowLimit: any, agentChannels: any) => {
	if (!windowLimit) {
		return;
	}
	const hasWAChannel = agentChannels?.includes(CHANNELS.WHATSAPP);
	const diffInMs = Math.abs(new Date(windowLimit).getTime() - new Date().getTime());

	if (diffInMs <= 21600000 && diffInMs >= 0 && hasWAChannel) {
		return windowLimit;
	}

	return null;
};

export const setFilterData = (value: any, savedFilters: any, availableAgents: any, defaultSort: any) => {
	let filterData = {
		facets: {},
		sort: defaultSort,
	};

	switch (value) {
		case SHORTCUT_QUICK_FILTER.NONE:
			return DEFAULT_FILTER_CONSTANT;
		case SHORTCUT_QUICK_FILTER.ONE_HOUR_WA_LIMIT:
		case SHORTCUT_QUICK_FILTER.THREE_HOURS_WA_LIMIT:
		case SHORTCUT_QUICK_FILTER.SIX_HOURS_WA_LIMIT:
			filterData = {
				facets: {
					channelsSelected: [{ id: CHANNELS.WHATSAPP, label: CHANNELS.WHATSAPP_TITLE_CASE }],
					conversationClosingTime: value,
				},
				sort: SORTING.conversationClosingTimeLTH,
			};
			break;
		case SHORTCUT_QUICK_FILTER.FIRST_RESPONSE_BREACH:
		case SHORTCUT_QUICK_FILTER.FIRST_RESOLUTION:
			filterData = {
				facets: {
					...filterData.facets,
					slaFilterSelected: [
						{
							label:
								value === SHORTCUT_QUICK_FILTER.FIRST_RESPONSE_BREACH
									? i18nHelper('components:messageFilter.First Response')
									: i18nHelper('components:messageFilter.Resolution'),
							id: `${value.toLowerCase().replace(' ', '_')}:"true"`,
						},
					],
				},
				sort: filterData.sort,
			};
			break;
		default:
			savedFilters
				.filter((data: any) => data.id === value)
				.forEach((data: any) => {
					const {
						filter_name,
						channels = [],
						agent_conversations_user_ids = [],
						managed_by_agent_ids = [],
						sla_non_adherence_types = [],
						joined_on_from_date,
						joined_on_to_date,
						conversation_status = [],
						tags = [],
						custom_filter,
						last_message_filter = [],
						window_closing_time,
					} = data;

					filterData = {
						facets: {
							...filterData.facets,
							customFilterGroupSelected: filter_name,
						},
						sort: filterData.sort,
					};

					// Handle Conversation Closing Time
					if (!isEmptyString(window_closing_time)) {
						filterData = {
							facets: {
								...filterData.facets,
								conversationClosingTime: window_closing_time,
							},
							sort: SORTING.conversationClosingTimeLTH,
						};
					}
					// Handle channels
					if (channels?.length) {
						filterData = {
							facets: {
								...filterData.facets,
								channelsSelected: channels.map((channel: any) => ({ id: channel, label: channel })),
							},
							sort: filterData.sort,
						};
					}
					// Handle agent_conversation, managed_bt
					if (agent_conversations_user_ids?.length || managed_by_agent_ids?.length) {
						const agentList: any = {};
						availableAgents.forEach(({ uid, user_name }: any) => {
							agentList[uid] = {
								id: `${uid}`,
								label: user_name,
							};
						});

						const agentConversationsFilter = agent_conversations_user_ids?.map((data_id: any) => {
							const { id, label } = agentList[data_id] || {};

							return { id, label };
						});

						const managedByFilter = managed_by_agent_ids?.map((data_id: any) => {
							const { id, label } = agentList[data_id] || {};

							return { id, label };
						});

						filterData = {
							facets: {
								...filterData.facets,
								agentConversationsFilter,
								managedByFilter,
							},
							sort: filterData.sort,
						};
					}
					// Handle sla
					if (sla_non_adherence_types?.length) {
						const slaFilterSelected: any =
							sla_non_adherence_types?.map((type: any) => {
								const label =
									type === SLA_FILTER.FIRST_RESPONSE
										? i18nHelper('components:messageFilter.First Response')
										: i18nHelper('components:messageFilter.Resolution');
								const id = `sla_${type.toLowerCase().replace(' ', '_')}_breach:"true"`;

								return { label, id };
							}) || [];

						filterData = {
							facets: {
								...filterData.facets,
								slaFilterSelected,
							},
							sort: filterData.sort,
						};
					}

					// Handle dateSelected
					if (joined_on_from_date && joined_on_to_date) {
						const dateSelected =
							data.joined_on_from_date && data.joined_on_to_date
								? [data.joined_on_from_date, data.joined_on_to_date]
								: null;

						filterData = {
							facets: {
								...filterData.facets,
								dateSelected,
							},
							sort: filterData.sort,
						};
					}
					// Handle statusSelected
					if (conversation_status?.length) {
						const statusSelected = (conversation_status || []).map((status: any) => {
							const { id, label } = allTabStatusFields().find((item: any) => item.label === status) || {};

							return { id, label };
						});
						filterData = {
							facets: {
								...filterData.facets,
								statusSelected,
							},
							sort: filterData.sort,
						};
					}
					// Handle tags
					if (tags?.length) {
						const tagsSelected = (tags || []).map((tag: any, index: number) => {
							const id = `tag${index}`;
							const label = tag;

							return { id, label };
						});
						filterData = {
							facets: {
								...filterData.facets,
								tagsSelected,
							},
							sort: filterData.sort,
						};
					}
					// Handle custom_filter
					if (Object.keys(custom_filter)?.length > 0 && custom_filter.solrQuery !== null) {
						const { custom_filter } = data;
						const { solrQuery } = custom_filter;

						const customFilterSelected = {
							isChecked: true,
							solrQuery,
						};

						filterData = {
							facets: {
								...filterData.facets,
								custom_filter,
								customFilterSelected,
							},
							sort: filterData.sort,
						};
					}
					// Handle last_message_filter
					if (last_message_filter?.length > 0) {
						const lastMessageFilter = (last_message_filter || []).map((item: any) => {
							const { id, label } = lastMessageFields().find((field) => field.id === item) || {};

							return { id, label };
						});
						filterData = {
							facets: {
								...filterData.facets,
								lastMessageFilter,
							},
							sort: filterData.sort,
						};
					}
				});
	}

	return filterData;
};

export const getDate = (utcFromDateString: any) => {
	const dateObject = new Date(utcFromDateString);

	const year = dateObject.getUTCFullYear();
	const month = dateObject.getUTCMonth() + 1;
	const day = dateObject.getUTCDate();

	return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
};

export const getFormattedDate = (dateSelected: any) => {
	const fromDate = new Date(dateSelected);

	return getDate(fromDate.toISOString());
};

export const areEqual = (arr1: any, arr2: any = []) => {
	if (arr1.length !== arr2?.length) {
		return false;
	}

	const stringifiedIdArray = arr1.map((element: any) => {
		if (typeof element !== 'string') {
			JSON.stringify(element);
		}
	});
	const stringifiedArray = arr2.map((element: any) => {
		if (typeof element !== 'string') {
			JSON.stringify(element);
		}
	});
	const sortedIdArray = stringifiedIdArray.slice().sort();
	const sortedArray = stringifiedArray?.slice().sort();

	return sortedIdArray.every((value: any, index: any) => value === sortedArray[index]);
};

export const getQueryParams = () => {
	const getQueryParamsFromUrl = new URLSearchParams(window.location.search);
	const showNeutralScreen = getQueryParamsFromUrl.get('neutralScreen') === 'true';

	return showNeutralScreen;
};

export const conversationIdCustomFilterSelected = ({
	botId,
	cid,
	uid,
	conversationId,
}: {
	botId: string;
	cid: string;
	uid: string;
	conversationId: string;
}) => {
	const allTabFilters = localStorage.getItem(`all_${botId}_${uid}`);
	const customFilterSelected = {
		id: null,
		botRef: botId,
		customerId: cid,
		filterExpression: 'A1',
		expression: `conversation_id is equal to ${conversationId}`,
		segmentName: null,
		solrQuery: `conversation_id:${conversationId}`,
		createdOn: null,
		createdBy: null,
		updatedOn: null,
		updatedBy: null,
		type: 'USER',
		rules: {
			A1: {
				lhOperand: 'conversation_id',
				rhOperand: conversationId,
				operator: 'IS_EQUAL_TO',
			},
		},
		inProgressRules: [],
		isChecked: true,
	};
	let updatedFilters: any = {
		facets: {},
		sort: 'last_active_at,desc',
	};
	if (allTabFilters) {
		updatedFilters = JSON.parse(allTabFilters);
		updatedFilters.facets.customFilterSelected = customFilterSelected;
	} else {
		updatedFilters.facets.customFilterSelected = customFilterSelected;
	}

	return updatedFilters;
};
