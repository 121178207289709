import { ENGATI_ROLES, ENGTFont } from 'shared/consts/consts';
import { WHATSAPP_PROVIDERS } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

export const instagramConfigured = (state: RootState) => state.Bot.data.is_instagram_configured;

export const facebookConfigured = (state: RootState) => state.Bot.data.is_facebook_configured;

export const isWhiteLabelPartnerSelector = (state: RootState) => !state.User.botAdmin.data.is_engati_brand;

export const isShopifyEnabledAcc = (state: RootState): boolean =>
	state.User.botAdmin.data.enable_shopify_shopper_assitance;

export const isWhatsappCampaignAnalyticsEnabledSelector = (state: RootState): boolean =>
	state.Bot.configData.enable_whatsapp_campaign_analytics;

export const isSellerAssistEnabledSelector = (state: RootState): boolean =>
	state.Bot.configData.enable_seller_assist_on_portal;

export const isShopifyPrivateAcc = (state: RootState): boolean => state.User.botAdmin.data.enable_shopify_private_app;

export const isShopifySegmentsEnabled = (state: RootState): boolean =>
	state.User.botAdmin.data.enable_shopify_system_segments;

export const isWhatsAppConfiguredSelector = (state: RootState) => state.Bot.data.is_whatsapp_configured;

export const isWhatsAppPaymentEnabledSelector = (state: RootState) => state.Bot.configData.enable_wa_payment;

export const isWhatsAppFlowEnabledSelector = (state: RootState) => state.Bot.configData.enable_wa_flows;

export const isDialog360ConfiguredAsProvider = (state: RootState) =>
	state.Bot.data.provider_configured === WHATSAPP_PROVIDERS.dialog360;

export const isTemplateCacheEntitlementEnabled = (state: RootState): boolean =>
	state.User.botAdmin.data.enable_template_cache;

export const isAirtelConfiguredAsProvider = (state: RootState) =>
	state.Bot.data.provider_configured === WHATSAPP_PROVIDERS.airtel;

export const isWhatsAppCloudConfiguredAsProvider = (state: RootState) =>
	state.Bot.data.provider_configured === WHATSAPP_PROVIDERS.whatsAppCloud;
export const isCommonWhatsappConfiguredAsProvider = (state: RootState) =>
	state.Bot.data.provider_configured === WHATSAPP_PROVIDERS.common_whatsapp;

export const currentBotRef = (state: RootState) => state.Bot.data.bot_ref;

export const isSandboxEntitlementEnabled = (state: RootState) => state.User.botAdmin.data.sandbox_enabled;

export const isSandboxConfiguredSelector = (state: RootState) => state.Bot.data.is_sandbox_configured;

export const emailIDSelector = (state: RootState) => state.User.botAdmin.data.email;

export const isContactsEnabled = (state: RootState) => state.User.botAdmin.data.is_contacts_enabled;

export const isLiveDraftEnabled = (state: RootState) => state.User.botAdmin.data.is_live_draft_enabled;

export const fontStyleSelector = (state: RootState) => state.User.botAdmin.data.font_style || ENGTFont;

export const isCognitiveSearchEntitlementEnabled = (state: RootState) =>
	state.User.botAdmin.data.enable_document_lookup || false;

export const isBroadcastV2EntitlementEnabled = (state: RootState) => state.User.botAdmin.data.enable_broadcast_v2;
export const isAggregatedBroadcastUserAnalyticsEntitlementEnabled = (state: RootState) =>
	state.User.botAdmin.data.enable_aggregated_broadcast_user_analytics;

export const isCreditBillingEnabledSelector = (state: RootState) => state.User.botAdmin.data.is_credit_billing_enabled;

export const isTestAccountSelector = (state: RootState) => state.User.botAdmin.data.is_test_account;

export const isRetargetViaContactUploadSelector = (state: RootState) =>
	state.User.botAdmin.data.enable_retarget_via_contact_upload;

export const isWhatsappAcceleratorTrialEnabled = (state: RootState) =>
	state.User.botAdmin.data.enable_whatsapp_accelerator_trial;

export const isWhatsappAcceleratorEnabled = (state: RootState) => state.User.botAdmin.data.enable_whatsapp_accelerator;

export const isWhatsappAcceleratorPaid = (state: RootState) =>
	state.User.botAdmin.data.enable_whatsapp_accelerator && !state.User.botAdmin.data.enable_whatsapp_accelerator_trial;

export const isWhatsappManagerEnabled = (state: RootState) => state.User.botAdmin.data.enable_whatsApp;

export const journeyPhaseSelector = (state: RootState) => state.User.journey;

export const isCreateMessageTemplatesV2EnabledSelector = (state: RootState) =>
	state.User.botAdmin.data.enable_create_message_templates_v2;

export const isEcomFeatureConfigV2Selector = (state: RootState) =>
	state.User.botAdmin.data.enable_ecom_feature_configurator_v2;

export const quickGuideUpsellEnabled = (state: RootState): boolean =>
	state.User.botAdmin.data.is_quick_guide_upsell_enabled && state.User.botAdmin.data.is_engati_brand;

export const isCtwaSegmentEnabledSelector = (state: RootState) => state.User.botAdmin.data.enable_ctwa_segment;

export const isEmailTemplatesEnabledSelector = (state: RootState) => state.User.botAdmin.data.enable_email_templates;

export const marketingCampaignsAnalyticsSelector = (state: RootState) =>
	state.User.botAdmin.data.enable_marketing_campaigns_analytics;

export const profileDataSelector = (state: RootState) => state.User.profile.data;

export const geoLocationSelector = (state: RootState) => state.User.geoLocation;

export const isWooCommerceAcceleratorEnabled = (state: RootState) =>
	state.User.botAdmin.data.is_woocommerce_accelerator_enabled;

export const isShopifyAcceleratorEnabled = (state: RootState) =>
	state.User.botAdmin.data.is_shopify_accelerator_enabled;

export const isAcceleratorEcomSelector = (state: RootState) =>
	state.User.botAdmin.data.is_woocommerce_accelerator_enabled ||
	state.User.botAdmin.data.is_shopify_accelerator_enabled;

export const isPricingPageEnabled = (state: RootState) => state.User.botAdmin.data.is_pricing_page_enabled;

export const isMaskingEnabledSelector = (state: RootState) =>
	state.User.botAdmin.data.enable_masking && state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT;

export const enableConversationReadFromMongodbSelector = (state: RootState) =>
	state.User.botAdmin.data.enable_conversation_read_from_mongodb;

export const isSupervisorSelector = (state: RootState) =>
	state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_SUPERVISOR;

export const isBotBuilderSelector = (state: RootState) =>
	state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_BOT_BUILDER;

export const isCTWAEntitlementEnabledSelector = (state: RootState) => state.User.botAdmin.data.enable_ctwa_entitlement;

export const isCDPEntitlementEnabledSelector = (state: RootState) => state.User.botAdmin.data.enable_cdp;

export const isCommonWAConfigurationEntitlementEnabled = (state: RootState) =>
	state.User.botAdmin.data.common_whatsapp_configuration_enabled;

export const themeColorSelector = (state: RootState) => state.User.botAdmin.data.theme_color;

export const timezoneSelector = (state: RootState) => state.User.profile.data.timezone;

export const customerAccountTypeSelector = (state: RootState) => state.User.botAdmin.data.customer_account_type;

export const brandingKeySelector = (state: RootState) => state.User.botAdmin.data.branding_key;

export const parentCidSelector = (state: RootState) => state.Plan.details.data.parentCustomerId;
