import { Navigate } from 'react-router-dom';

import loadable from '@loadable/component';

import { IRouteProps } from 'shared/consts/types';
import {
	ADVANCED_TAB,
	AGENT_ASSISTANCE_FEEDBACK_TABS,
	CUSTOMERS_TAB,
	ENGAGEMENT_TAB,
	EXECUTION_TAB,
	NEW_AGENT_PERFORMANCE_TAB,
	SHOPIFY_CONVERSION,
} from 'shared/enum';

import Engagement from 'pages/BroadcastRevamp/Engagement/Engagement';
import Execution from 'pages/BroadcastRevamp/Execution/Execution';
import BroadcastRedirection from 'pages/BroadcastRevamp/Home/BroadcastRedirection';
import Integration from 'pages/Integrations';
import WhatsAppManager from 'pages/WhatsApp';

import { ThreeColumnRouteLinks } from './links/ThreeColumnRouteLinks';

const Attributes = loadable(() => import('pages/Configure/Attributes/Attributes' /* webpackChunkName: "Attribute" */));

const Display = loadable(() => import('pages/Configure/Display/Display' /* webpackChunkName: "Display" */));
const Deployment = loadable(() => import('pages/Configure/Deployment/Deployment' /* webpackChunkName: "Deployment" */));
const Publish = loadable(() => import('pages/Configure/Publish/Publish' /* webpackChunkName: "Publish" */));
const VersionHistory = loadable(
	() => import('pages/Configure/VersionHistory/VersionHistory' /* webpackChunkName: "VersionHistory" */)
);
const EmailDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Email/index' /* webpackChunkName: "EmailDeployment" */)
);
const GbmDeployment = loadable(
	() => import('pages/Configure/Deployment/components/gbm' /* webpackChunkName: "GbmDeployment" */)
);
const FacebookDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Facebook/Facebook' /* webpackChunkName: "FacebookDeployment" */)
);
const InstagramDeployment = loadable(
	() =>
		import(
			'pages/Configure/Deployment/components/Instagram/Instagram' /* webpackChunkName: "InstagramDeployment" */
		)
);
const KikDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Kik' /* webpackChunkName: "KikDeployment" */)
);
const LineDeployment = loadable(
	() => import('pages/Configure/Deployment/components/line' /* webpackChunkName: "LineDeployment" */)
);
const MicroSoftTeamsDeployment = loadable(
	() =>
		import(
			'pages/Configure/Deployment/components/microsoftTeams' /* webpackChunkName: "MicroSoftTeamsDeployment" */
		)
);
const SkypeBusinessDeployment = loadable(
	() =>
		import(
			'pages/Configure/Deployment/components/skypeForBusiness' /* webpackChunkName: "SkypeBusinessDeployment" */
		)
);
const SkypeDeployment = loadable(
	() => import('pages/Configure/Deployment/components/skype' /* webpackChunkName: "SkypeDeployment" */)
);
const SlackDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Slack' /* webpackChunkName: "SlackDeployment" */)
);
const TelegramDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Telegram' /* webpackChunkName: "TelegramDeployment" */)
);
const TwitterDeployment = loadable(
	() => import('pages/Configure/Deployment/components/twitter' /* webpackChunkName: "TwitterDeployment" */)
);
const ViberDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Viber' /* webpackChunkName: "ViberDeployment" */)
);
const WeChatDeployment = loadable(
	() => import('pages/Configure/Deployment/components/weChat' /* webpackChunkName: "WeChatDeployment" */)
);
const WebsiteDeployment = loadable(
	() => import('pages/Configure/Deployment/components/WebsiteDeployment' /* webpackChunkName: "WebsiteDeployment" */)
);
const WhatsappDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Whatsapp' /* webpackChunkName: "WhatsappDeployment" */)
);
const WhatsappBusinessProfile = loadable(
	() =>
		import(
			'pages/Configure/Deployment/components/WhatsappBusinessProfile' /* webpackChunkName: "WhatsappBusinessProfile" */
		)
);
const MobileDeployment = loadable(
	() => import('pages/Configure/Deployment/components/Mobile' /* webpackChunkName: "MobileDeployment" */)
);
const TrainOverview = loadable(() => import('pages/Train/Overview/Overview' /* webpackChunkName: "OverviewTrain" */));
const ConfigureOverview = loadable(
	() => import('pages/Configure/Overview' /* webpackChunkName: "OverviewConfigure" */)
);
const Faqs = loadable(() => import('pages/Train/Faqs/Faqs' /* webpackChunkName: "Faqs" */));
const FaqCategories = loadable(
	() => import('pages/Train/Faqs/FaqCategories/FaqCategories' /* webpackChunkName: "FaqCategories" */)
);
const ConfigureSynonyms = loadable(
	() => import('pages/Train/Faqs/ConfigureSynonyms/ConfigureSynonyms' /* webpackChunkName: "ConfigureSynonyms" */)
);
const ChangeHistory = loadable(
	() => import('pages/Configure/Attributes/ChangeHistory' /* webpackChunkName: "ChangeHistory" */)
);
const LiveChatConfigure = loadable(
	() => import('pages/Configure/LiveChat' /* webpackChunkName: "LiveChatConfigure" */)
);
const ExternalLcConfig = loadable(
	() =>
		import('pages/Configure/ExternalLiveChat/ExternalLcConfig' /* webpackChunkName: "ExternalLiveChatConfigure" */)
);
const CloudIntegrationsRedirect = loadable(
	() =>
		import(
			'pages/Integrations/CloudIntegrations/CloudIntegrationsRedirect' /* webpackChunkName: "CloudIntegrationsRedirect" */
		)
);
const Numbers = loadable(() => import('pages/WhatsApp/Numbers/Numbers' /* webpackChunkName: "WhatsAppNumbers" */));
const WhatsAppFlows = loadable(() => import('pages/WhatsApp/Flows/Flows' /* webpackChunkName: "WhatsAppFlows" */));
const EngatiAPI = loadable(() => import('pages/Integrations/EngatiAPI/EngatiAPI' /* webpackChunkName: "EngatiAPI" */));
const CtoEBridge = loadable(() => import('pages/Integrations/C2EBridge/C2EBridge' /* webpackChunkName: "C2EBridge" */));
const ChannelLogs = loadable(
	() => import('pages/Integrations/ChannelLogs/ChannelLogs' /* webpackChunkName: "ChannelLogs" */)
);
const PaymentLogs = loadable(
	() => import('pages/Integrations/PaymentLogs/PaymentLogs' /* webpackChunkName: "PaymentLogs" */)
);
const BotDetails = loadable(() => import('pages/Configure/BotDetails/botDetails' /* webpackChunkName: "BotDetails" */));
const ConfigureAdvancedIndex = loadable(
	() => import('pages/Configure/Advanced' /* webpackChunkName: "ConfigureAdvanced" */)
);
const ConfigureAdvanced = loadable(
	() => import('pages/Configure/Advanced/Advanced' /* webpackChunkName: "ConfigureAdvanced" */)
);
const ResponseAnalytics = loadable(
	() => import('pages/Analytics/Bot/ResponseAnalytics' /* webpackChunkName: "ResponseAnalytics" */)
);
const RetentionAnalytics = loadable(
	() => import('pages/Analytics/User/UserRetention' /* webpackChunkName: "RetentionAnalytics" */)
);
const EngagementAnalytics = loadable(
	() => import('pages/Analytics/Bot/EngagementAnalytics' /* webpackChunkName: "EngagementAnalytics" */)
);
const Shopify = loadable(() => import('pages/Configure/Shopify/Shopify' /* webpackChunkName: "Shopify" */));
const ChannelAnalytics = loadable(
	() => import('pages/Analytics/Bot/ChannelAnalytics' /* webpackChunkName: "ChannelAnalytics" */)
);
const AgentAnalytics = loadable(
	() => import('pages/Analytics/LiveChat/AgentAnalytics' /* webpackChunkName: "AgentAnalytics" */)
);
const AgentPerformance = loadable(
	() => import('pages/Analytics/LiveChat/AgentPerformance' /* webpackChunkName: "AgentPerformance" */)
);
const AgentConversions = loadable(
	() => import('pages/Analytics/LiveChat/AgentConversions' /* webpackChunkName: "AgentConversions" */)
);
const PurchaseFunnel = loadable(
	() => import('pages/Analytics/LiveChat/PurchaseFunnel' /* webpackChunkName: "PurchaseFunnel" */)
);

const ProductAnalytics = loadable(
	() => import('pages/Analytics/LiveChat/ProductAnalytics' /* webpackChunkName: "ProductAnalytics" */)
);

const NewAgentPerformanceIndex = loadable(
	() => import('pages/Analytics/LiveChat/NewAgentPerformance' /* webpackChunkName: "AgentPerformanceNewIndex" */)
);
const NewAgentPerformance = loadable(
	() =>
		import(
			'pages/Analytics/LiveChat/NewAgentPerformance/NewAgentPerformance' /* webpackChunkName: "AgentPerformanceNew" */
		)
);
const AgentProductivity = loadable(
	() =>
		import(
			'pages/Analytics/LiveChat/AgentProductivity/AgentProductivity' /* webpackChunkName: "AgentProductivity" */
		)
);
const LiveChatSummary = loadable(
	() => import('pages/Analytics/LiveChat/SummaryAnalytics' /* webpackChunkName: "LiveChatSummary" */)
);
const AnalyticsHomeRouter = loadable(
	() => import('pages/Analytics/AnalyticsHomeRouter' /* webpackChunkName: "AnalyticsHomeRouter" */)
);
const ConfigureHomeRouter = loadable(
	() => import('pages/Configure/ConfigureHomeRouter' /* webpackChunkName: "ConfigureHomeRouter" */)
);
const AnalyticsOverview = loadable(
	() => import('pages/Analytics/Overview' /* webpackChunkName: "OverviewAnalytics" */)
);
const ConfigAgentProductivity = loadable(
	() =>
		import('pages/Configure/AgentProductivity/AgentProductivity' /* webpackChunkName: "ConfigAgentProductivity" */)
);
const UploadHistory = loadable(
	() => import('pages/Train/Faqs/UploadHistory/UploadHistory' /* webpackChunkName: "UploadHistory" */)
);
const TrainSettings = loadable(
	() => import('pages/Train/Settings/TrainSettings' /* webpackChunkName: "TrainSettings" */)
);
const BroadcastHistoryIndex = loadable(
	() => import(/* webpackChunkName: "BroadcastHistoryIndex" */ 'pages/BroadcastRevamp/BroadcastHistory/index')
);
const ExecutionIndex = loadable(
	() => import(/* webpackChunkName: "Execution" */ 'pages/BroadcastRevamp/Execution/index')
);
const EngagementIndex = loadable(
	() => import(/* webpackChunkName: "Engagement" */ 'pages/BroadcastRevamp/Engagement/index')
);
const Conversion = loadable(
	() => import(/* webpackChunkName: "Conversion" */ 'pages/BroadcastRevamp/Conversion/Conversion')
);
const PulseReport = loadable(
	() => import(/* webpackChunkName: "PulseReport" */ 'pages/BroadcastRevamp/Pulse/PulseReport')
);
const Pulse = loadable(() => import(/* webpackChunkName: "Pulse" */ 'pages/BroadcastRevamp/Pulse/Pulse'));
const MicroAnalytics = loadable(
	() =>
		import(
			/* webpackChunkName: "MicroAnalytics" */ 'pages/BroadcastRevamp/BroadcastHistory/components/MicroAnalytics'
		)
);
const ExportHistoryV2 = loadable(
	() => import(/* webpackChunkName: "ExportHistoryV2" */ 'pages/BroadcastRevamp/ExportHistory/index')
);
const CampaignsIndex = loadable(
	() => import(/* webpackChunkName: "CampaignsIndex" */ 'pages/BroadcastRevamp/Campaigns/index')
);
const CampaignMicroAnalytics = loadable(
	() =>
		import(
			/* webpackChunkName: "CampaignsMicroAnalytics" */ 'pages/BroadcastRevamp/Campaigns/components/MicroAnalytics'
		)
);
const CognitiveSearch = loadable(
	() => import('pages/Train/CognitiveSearch/CognitiveSearch' /* webpackChunkName: "CognitiveSearch" */)
);
const BroadcastHelp = loadable(
	() => import('pages/BroadcastRevamp/Help/index' /* webpackChunkName: "BroadcastHelp" */)
);
const ScheduledBroadcast = loadable(
	() => import(/* webpackChunkName: "ScheduledBroadcast" */ 'pages/BroadcastRevamp/Scheduled/index')
);
const DraftBroadcast = loadable(
	() => import(/* webpackChunkName: "DraftBroadcast" */ 'pages/BroadcastRevamp/Drafts/index')
);
const CreateBroadcast = loadable(
	() => import('pages/BroadcastRevamp/CreateBroadcast' /* webpackChunkName: "CreateBroadcast" */)
);
const ClickToWhatsappAds = loadable(
	() => import('pages/BroadcastRevamp/ClickToWhatsappAds' /* webpackChunkName: "ClickToWhatsappAds" */)
);
const MessageTemplates = loadable(
	() => import('pages/BroadcastRevamp/MessageTemplates/index' /* webpackChunkName: "MessageTemplates" */)
);
const EmailTemplates = loadable(
	() => import('pages/BroadcastRevamp/EmailTemplates/index' /* webpackChunkName: "EmailTemplates" */)
);
const CreateMessageTemplates = loadable(
	() => import('pages/BroadcastRevamp/MessageTemplatesRevamp/index' /* webpackChunkName: "CreateMessageTemplates" */)
);
const RedirectLogic = loadable(
	() => import('pages/Store/Components/RedirectLogic' /* webpackChunkName: "RedirectLogic" */)
);
const CustomersIndex = loadable(() => import('pages/Store/Customers' /* webpackChunkName: "CustomersIndex" */));
const Customers = loadable(() => import('pages/Store/Customers/Customers' /* webpackChunkName: "Customers" */));
const Products = loadable(() => import('pages/Store/Products' /* webpackChunkName: "Products" */));
const StoreConversionIndex = loadable(
	() => import('pages/Store/StoreConversion' /* webpackChunkName: "ConversionTriggers" */)
);
const StoreConversion = loadable(
	() => import('pages/Store/StoreConversion/Conversion' /* webpackChunkName: "ConversionTriggers" */)
);
const FeatureConfigurator = loadable(
	() => import('pages/Store/FeatureConfigurator' /* webpackChunkName: "FeatureConfigurator" */)
);
const Setup = loadable(() => import('pages/Store/Setup' /* webpackChunkName: "Setup" */));
const Entities = loadable(() => import('pages/Train/Entities/Entities' /* webpackChunkName: "Entities" */));
const TabularInput = loadable(
	() => import('pages/Train/TabularInput/TabularInput' /* webpackChunkName: "TabularInput" */)
);
const Integrations = loadable(
	() => import('pages/Configure/Integration/Integrations' /* webpackChunkName: "ConfigureIntegrations" */)
);
const WooCommerce = loadable(
	() => import('pages/Configure/Integration/Woocommerce' /* webpackChunkName: "WooCommerce" */)
);
const GoogleTranslate = loadable(
	() =>
		import('pages/Configure/Integration/GoogleTranslate/GoogleTranslate' /* webpackChunkName: "GoogleTranslate" */)
);
const Shiprocket = loadable(
	() => import('pages/Configure/Integration/Shiprocket/Shiprocket' /* webpackChunkName: "Shiprocket" */)
);
const Razorpay = loadable(
	() => import('pages/Configure/Integration/Razorpay/Razorpay' /* webpackChunkName: "Razorpay" */)
);
const Gorgias = loadable(() => import('pages/Configure/Integration/Gorgias/Gorgias' /* webpackChunkName: "Gorgias" */));
const Zendesk = loadable(() => import('pages/Configure/Integration/Zendesk/Zendesk' /* webpackChunkName: "Zendesk" */));
const Salesforce = loadable(
	() => import('pages/Configure/Integration/Salesforce/Salesforce' /* webpackChunkName: "SalesForce" */)
);
const GoogleAdmin = loadable(
	() => import('pages/Configure/Integration/GoogleAdmin/GoogleAdmin' /* webpackChunkName: "GoogleAdmin" */)
);
const Sms = loadable(() => import('pages/Configure/Integration/Sms/Sms' /* webpackChunkName: "Sms" */));
const Freshdesk = loadable(
	() => import('pages/Configure/Integration/Freshdesk/Freshdesk' /* webpackChunkName: "Freshdesk" */)
);
const GoogleAnalytics = loadable(
	() =>
		import('pages/Configure/Integration/GoogleAnalytics/GoogleAnalytics' /* webpackChunkName: "GoogleAnalytics" */)
);
const Responses = loadable(() => import('pages/Train/Responses/Responses' /* webpackChunkName: "ResponsesTrain" */));
const Languages = loadable(
	() => import('pages/Configure/Languages/Languages' /* webpackChunkName: "LanguagesConfigure" */)
);
const LocaleKeyUploadHistory = loadable(
	() => import('pages/Configure/Languages/LocaleKeyUploadHistory' /* webpackChunkName: "LocaleKeyUploadHistory" */)
);
const ExportHistory = loadable(
	() => import('pages/Train/Responses/ExportHistory' /* webpackChunkName: "ExportHistory" */)
);
const FileUploadAnalytics = loadable(
	() => import('pages/Train/Responses/FileUploadAnalytics' /* webpackChunkName: "FileUploadAnalytics" */)
);
const ApiErrorHistory = loadable(
	() => import('pages/Train/Responses/ApiErrorHistory' /* webpackChunkName: "ApiErrorHistory" */)
);
const ApiLogs = loadable(() => import('pages/Integrations/ApiLogs/ApiLogs' /* webpackChunkName: "ApiLogs" */));
const ConversationOverview = loadable(
	() => import('pages/Analytics/LiveChat/ConversationOverview' /* webpackChunkName: "ConversationOverview" */)
);
const whatsappComplianceInfo = loadable(
	() =>
		import(
			'pages/Configure/Deployment/components/Whatsapp/complianceInfo' /* webpackChunkName: "WhatsappCompliance" */
		)
);
const ESenseGPT = loadable(
	() => import('pages/Configure/Integration/ESenseGPT/ESenseGPT' /* webpackChunkName: "eSenseGPT" */)
);
const AgentAssistanceFeedBackIndex = loadable(
	() => import('pages/Analytics/LiveChat/AgentAssistanceFeedBack' /* webpackChunkName: "AgentAssitanceFeedBack" */)
);

const AgentAssistanceFeedBack = loadable(
	() =>
		import(
			'pages/Analytics/LiveChat/AgentAssistanceFeedBack/AgentAssistanceFeedBack' /* webpackChunkName: "AgentAssitanceFeedBack" */
		)
);
const SocialInboxLayout = loadable(() => import('pages/Messages/SocialInbox' /* webpackChunkName: "SocialInbox" */));
const CommentsListWrapper = loadable(
	() =>
		import(
			'pages/Messages/SocialInbox/components/CommentsListWrapper' /* webpackChunkName: "CommentsListWrapper" */
		)
);
const AnalyticsDashboard = loadable(
	() => import('pages/Analytics/Bot/AnalyticsDashboard' /* webpackChunkName: "AnalyticsDashboard" */)
);
const AIAgentsRouter = loadable(() => import('pages/Agents/AIAgentsRouter' /* webpackChunkName: "AIAgents" */));
const AIAgentManagement = loadable(
	() => import('pages/Agents/components/buildAndManageAgents/AllAgents' /* webpackChunkName: "AIAgents" */)
);
const CreateAIAgent = loadable(
	() => import('pages/Agents/components/createAgent/ViewOrCreateAgent' /* webpackChunkName: "AIAgents" */)
);
const AgentsWorkflows = loadable(
	() => import('pages/Agents/components/workflows/Workflows' /* webpackChunkName: "AIAgents" */)
);
const AgentsRoute = loadable(
	() => import('pages/Agents/components/route/Supervisor' /* webpackChunkName: "AIAgents" */)
);

const {
	analyticsLink,
	analyticsOverviewLink,
	broadcastHomeLink,
	broadCastHelpLink,
	broadcastHistoryLink,
	executionLink,
	createBroadcast,
	broadcastAnalyticsSelected,
	clickToWhatsappAdsLink,
	scheduledBroadcastLink,
	draftBroadcastLink,
	exportHistoryLink,
	campaignsLink,
	campaignAnalyticsSelected,
	broadcastMessageTemplates,
	createBroadcastMessageTemplates,
	broadcastEmailTemplates,
	storeHomeLink,
	storeCustomersLink,
	storeProductsLink,
	storeConversionTriggersLink,
	storeFeatureConfiguratorLink,
	storeSetupLink,
	userRetentionLink,
	analyticsBotRespLink,
	analyticsBotEngageLink,
	analyticsChannelLink,
	analyticsChannelAgents,
	analyticsSummaryLink,
	analyticsPerformanceLink,
	analyticsConversionsLink,
	purchaseFunnelLink,
	productAnalyticsLink,
	newanalyticsPerformanceLink,
	analyticsProductivityLink,
	configureHomeLink,
	configureOverviewLink,
	conversionLink,
	configureDetailsLink,
	configureAttributesLink,
	configurePublishLink,
	configureVersionHistoryLink,
	configureDisplayLink,
	configureAttributesHistoryLink,
	configureLcLink,
	configureLcSettingsLink,
	configureExternalLcLink,
	configureDeploymentLink,
	configureDepEmailLink,
	configureDepFbLink,
	configureDepInstaLink,
	configureDepGbmLink,
	configureDepKikLink,
	configureDepLineLink,
	configureDepTeamsLink,
	configureDepMobileLink,
	configureDepSkypeBusLink,
	configureDepSkypeLink,
	configureDepWebLink,
	configureDepSlackLink,
	configureDepTelegramLink,
	configureDepTwitterLink,
	configureDepViberLink,
	configureDepWeChatLink,
	cofigureDepWhatsappBusinessProfileLink,
	configureDepWhatsappLink,
	configureAgentProductivity,
	configureAdvancedLink,
	conversationOverviewLink,
	integrationsHomeLink,
	cloudIntegrationsLink,
	whatsAppHomeLink,
	whatsAppNumbersLink,
	whatsAppFlowsLink,
	engatiApiLink,
	engagementLink,
	c2eBridgeLink,
	channelLogsLink,
	paymentLogsLink,
	apiLogsLink,
	confiureLanguagesLink,
	confiureLanguagesHistoryLink,
	trainHomeLink,
	trainCognitiveLink,
	trainEntitiesLink,
	trainFaqLink,
	trainConfigSynonymsLink,
	trainUploadHistoryLink,
	trainCategoriesLink,
	trainOverviewLink,
	trainSettingsLink,
	trainTabularInpLink,
	configureIntegrationsLink,
	gaIntegrationLink,
	googleTranslateIntegrationLink,
	shiprocketIntegrationLink,
	razorpayIntegrationLink,
	woocommerceIntegrationLink,
	gorgiasIntegrationLink,
	zendeskIntegrationLink,
	salesforceIntegrationLink,
	googleAdminIntegrationLink,
	freshdeskIntegrationLink,
	smsIntegrationLink,
	eSenseGPTIntegrationLink,
	GPTIntegrationLink,
	trainResponsesLink,
	trainExpHistoryLink,
	trainFileUplAnalyticsLink,
	trainApiErrorHistoryLink,
	configureShopifyLink,
	whatsappComplianceInfoLink,
	agentAssistanceFeedBackLink,
	pulseLink,
	pulseReport,
	socialInboxFacebook,
	socialInboxFacebookPost,
	socialInboxInstagram,
	socialInboxInstagramPost,
	slaBreaches,
	buttonClickAnalysis,
	genAIQueryAnalysis,
	liveChatDetails,
	marketingCampaigns,
	feedbackSummary,
	platformInteractions,
	automationEfficiency,
	aiAgentsLink,
	aiAgentsManagementLink,
	createAIAgentLink,
	viewAgentDetailsLink,
	agentsWorkflows,
	agentsRoute,
} = ThreeColumnRouteLinks;

const ThreeColumnRoutes: Array<IRouteProps> = [
	{
		path: analyticsLink,
		component: AnalyticsHomeRouter,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsOverviewLink,
		component: AnalyticsOverview,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: broadcastHomeLink,
		component: BroadcastRedirection,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: broadCastHelpLink,
		component: BroadcastHelp,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: broadcastMessageTemplates,
		component: MessageTemplates,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: broadcastEmailTemplates,
		component: EmailTemplates,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: createBroadcastMessageTemplates,
		component: CreateMessageTemplates,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: broadcastHistoryLink,
		component: BroadcastHistoryIndex,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: executionLink,
		component: ExecutionIndex,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
		subRoutes: [
			{
				path: EXECUTION_TAB.BROADCAST,
				element: <Execution tab={EXECUTION_TAB.BROADCAST} />,
			},
			{
				path: EXECUTION_TAB.CTWA,
				element: <Execution tab={EXECUTION_TAB.CTWA} />,
			},
			{
				path: EXECUTION_TAB.CAMPAIGN_AUTOMATION,
				element: <Execution tab={EXECUTION_TAB.CAMPAIGN_AUTOMATION} />,
			},
		],
	},
	{
		path: engagementLink,
		component: EngagementIndex,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
		subRoutes: [
			{
				path: ENGAGEMENT_TAB.SUMMARY,
				element: <Engagement tab={ENGAGEMENT_TAB.SUMMARY} />,
			},
			{
				path: ENGAGEMENT_TAB.BROADCAST,
				element: <Engagement tab={ENGAGEMENT_TAB.BROADCAST} />,
			},
			{
				path: ENGAGEMENT_TAB.CTWA,
				element: <Engagement tab={ENGAGEMENT_TAB.CTWA} />,
			},
		],
	},
	{
		path: conversionLink,
		component: Conversion,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: pulseLink,
		component: Pulse,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: pulseReport,
		component: PulseReport,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: broadcastAnalyticsSelected,
		component: MicroAnalytics,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: clickToWhatsappAdsLink,
		component: ClickToWhatsappAds,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: scheduledBroadcastLink,
		component: ScheduledBroadcast,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: draftBroadcastLink,
		component: DraftBroadcast,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: createBroadcast,
		component: CreateBroadcast,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: exportHistoryLink,
		component: ExportHistoryV2,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: campaignsLink,
		component: CampaignsIndex,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: campaignAnalyticsSelected,
		component: CampaignMicroAnalytics,
		options: {
			secondNav: {
				page: 'broadcast',
			},
		},
	},
	{
		path: storeHomeLink,
		component: RedirectLogic,
		options: {
			secondNav: {
				page: 'store',
			},
		},
	},
	{
		path: storeCustomersLink,
		component: CustomersIndex,
		options: {
			secondNav: {
				page: 'store',
			},
		},
		subRoutes: [
			{
				path: CUSTOMERS_TAB.SHOPIFY_SEGMENT,
				element: <Customers tab={CUSTOMERS_TAB.SHOPIFY_SEGMENT} />,
			},
			{
				path: CUSTOMERS_TAB.SYSTEM_SEGMENT,
				element: <Customers tab={CUSTOMERS_TAB.SYSTEM_SEGMENT} />,
			},
			{
				path: CUSTOMERS_TAB.CUSTOM_SEGMENT,
				element: <Customers tab={CUSTOMERS_TAB.CUSTOM_SEGMENT} />,
			},
			{
				path: CUSTOMERS_TAB.EXPORT,
				element: <Customers tab={CUSTOMERS_TAB.EXPORT} />,
			},
			{
				path: CUSTOMERS_TAB.CTWA_SEGMENT,
				element: <Customers tab={CUSTOMERS_TAB.CTWA_SEGMENT} />,
			},
		],
	},
	{
		path: storeProductsLink,
		component: Products,
		options: {
			secondNav: {
				page: 'store',
			},
		},
	},
	{
		path: storeFeatureConfiguratorLink,
		component: FeatureConfigurator,
		options: {
			secondNav: {
				page: 'store',
			},
		},
	},
	{
		path: storeConversionTriggersLink,
		component: StoreConversionIndex,
		options: {
			secondNav: {
				page: 'store',
			},
		},
		subRoutes: [
			{
				path: SHOPIFY_CONVERSION.TRIGGERS,
				element: <StoreConversion tab={SHOPIFY_CONVERSION.TRIGGERS} />,
			},
			{
				path: SHOPIFY_CONVERSION.POPUP_MODAL_CONFIG_SETUP,
				element: <StoreConversion tab={SHOPIFY_CONVERSION.POPUP_MODAL_CONFIG_SETUP} />,
			},
		],
	},
	{
		path: storeSetupLink,
		component: Setup,
		options: {
			secondNav: {
				page: 'store',
			},
		},
	},
	{
		path: userRetentionLink,
		component: RetentionAnalytics,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsBotRespLink,
		component: ResponseAnalytics,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsBotEngageLink,
		component: EngagementAnalytics,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsChannelLink,
		component: ChannelAnalytics,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsChannelAgents,
		component: AgentAnalytics,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsSummaryLink,
		component: LiveChatSummary,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: conversationOverviewLink,
		component: ConversationOverview,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsPerformanceLink,
		component: AgentPerformance,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: analyticsConversionsLink,
		component: AgentConversions,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: purchaseFunnelLink,
		component: PurchaseFunnel,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: productAnalyticsLink,
		component: ProductAnalytics,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: newanalyticsPerformanceLink,
		component: NewAgentPerformanceIndex,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
		subRoutes: [
			{
				path: NEW_AGENT_PERFORMANCE_TAB.QUALITY,
				element: <NewAgentPerformance tab={NEW_AGENT_PERFORMANCE_TAB.QUALITY} />,
			},
			{
				path: NEW_AGENT_PERFORMANCE_TAB.PRODUCTIVITY,
				element: <NewAgentPerformance tab={NEW_AGENT_PERFORMANCE_TAB.PRODUCTIVITY} />,
			},
			{
				path: NEW_AGENT_PERFORMANCE_TAB.PERFORMANCE,
				element: <NewAgentPerformance tab={NEW_AGENT_PERFORMANCE_TAB.PERFORMANCE} />,
			},
		],
	},
	{
		path: agentAssistanceFeedBackLink,
		component: AgentAssistanceFeedBackIndex,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
		subRoutes: [
			{
				path: AGENT_ASSISTANCE_FEEDBACK_TABS.OVERALL_FEEDBACK,
				element: <AgentAssistanceFeedBack tab={AGENT_ASSISTANCE_FEEDBACK_TABS.OVERALL_FEEDBACK} />,
			},
			{
				path: AGENT_ASSISTANCE_FEEDBACK_TABS.AGENT_WISE_FEEDBACK,
				element: <AgentAssistanceFeedBack tab={AGENT_ASSISTANCE_FEEDBACK_TABS.AGENT_WISE_FEEDBACK} />,
			},
		],
	},
	{
		path: analyticsProductivityLink,
		component: AgentProductivity,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: slaBreaches,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: buttonClickAnalysis,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: genAIQueryAnalysis,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: liveChatDetails,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: marketingCampaigns,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: feedbackSummary,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: platformInteractions,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: automationEfficiency,
		component: AnalyticsDashboard,
		options: {
			secondNav: {
				page: 'analytics',
			},
		},
	},
	{
		path: configureHomeLink,
		component: ConfigureHomeRouter,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureOverviewLink,
		component: ConfigureOverview,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDetailsLink,
		component: BotDetails,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureAttributesLink,
		component: Attributes,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configurePublishLink,
		component: Publish,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureVersionHistoryLink,
		component: VersionHistory,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDisplayLink,
		component: Display,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureAttributesHistoryLink,
		component: ChangeHistory,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureLcLink,
		component: () => <Navigate to='/configure/live-chat/setup' />,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureLcSettingsLink,
		component: LiveChatConfigure,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureExternalLcLink,
		component: ExternalLcConfig,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDeploymentLink,
		component: Deployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepEmailLink,
		component: EmailDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepFbLink,
		component: FacebookDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepInstaLink,
		component: InstagramDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepGbmLink,
		component: GbmDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepKikLink,
		component: KikDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepLineLink,
		component: LineDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepTeamsLink,
		component: MicroSoftTeamsDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepMobileLink,
		component: MobileDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepSkypeBusLink,
		component: SkypeBusinessDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepSkypeLink,
		component: SkypeDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepWebLink,
		component: WebsiteDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepSlackLink,
		component: SlackDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepTelegramLink,
		component: TelegramDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepTwitterLink,
		component: TwitterDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepViberLink,
		component: ViberDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepWeChatLink,
		component: WeChatDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: cofigureDepWhatsappBusinessProfileLink,
		component: WhatsappBusinessProfile,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: whatsappComplianceInfoLink,
		component: whatsappComplianceInfo,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureDepWhatsappLink,
		component: WhatsappDeployment,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureAgentProductivity,
		component: ConfigAgentProductivity,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
	},
	{
		path: configureAdvancedLink,
		component: ConfigureAdvancedIndex,
		options: {
			secondNav: {
				page: 'configure',
			},
		},
		subRoutes: [
			{ path: ADVANCED_TAB.VOICE, element: <ConfigureAdvanced tab={ADVANCED_TAB.VOICE} /> },
			{ path: ADVANCED_TAB.FORMATTING, element: <ConfigureAdvanced tab={ADVANCED_TAB.FORMATTING} /> },
			{ path: ADVANCED_TAB.SECURITY, element: <ConfigureAdvanced tab={ADVANCED_TAB.SECURITY} /> },
		],
	},
	{
		path: integrationsHomeLink,
		component: Integration,
		options: {
			secondNav: {
				page: 'integrations',
				heading: 'Integrations',
			},
		},
	},
	{
		path: cloudIntegrationsLink,
		component: CloudIntegrationsRedirect,
		options: {
			secondNav: {
				page: 'integrations',
				heading: 'Integrations',
				note: {
					description: 'common:integrationDescription',
				},
			},
		},
	},
	{
		path: whatsAppNumbersLink,
		component: Numbers,
		options: {
			secondNav: {
				page: 'whatsApp',
				heading: 'Numbers',
			},
		},
	},
	{
		path: whatsAppFlowsLink,
		component: WhatsAppFlows,
		options: {
			secondNav: {
				page: 'whatsApp',
				heading: 'Flows',
			},
		},
	},
	{
		path: whatsAppHomeLink,
		component: WhatsAppManager,
		options: {
			secondNav: {
				page: 'whatsApp',
				heading: 'whatsApp',
			},
		},
	},
	{
		path: engatiApiLink,
		component: EngatiAPI,
		options: {
			secondNav: {
				page: 'integrations',
				heading: 'Integrations',
				note: {
					description: 'common:integrationDescription',
				},
			},
		},
	},
	{
		path: c2eBridgeLink,
		component: CtoEBridge,
		options: {
			secondNav: {
				page: 'integrations',
				heading: 'Integrations',
				note: {
					description: 'common:integrationDescription',
				},
			},
		},
	},
	{
		path: channelLogsLink,
		component: ChannelLogs,
		options: {
			secondNav: {
				page: 'integrations',
				heading: 'Integrations',
				note: {
					description: 'common:integrationDescription',
				},
			},
		},
	},
	{
		path: paymentLogsLink,
		component: PaymentLogs,
		options: {
			secondNav: {
				page: 'integrations',
				heading: 'Integrations',
				note: {
					description: 'common:integrationDescription',
				},
			},
		},
	},
	{
		path: apiLogsLink,
		component: ApiLogs,
		options: {
			secondNav: {
				page: 'integrations',
				heading: 'Integrations',
				note: {
					description: 'common:integrationDescription',
				},
			},
		},
	},
	{
		path: confiureLanguagesLink,
		component: Languages,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Languages',
				isHeaderShown: false,
			},
		},
	},
	{
		path: confiureLanguagesHistoryLink,
		component: LocaleKeyUploadHistory,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Languages',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainHomeLink,
		component: () => <Navigate to='/train/overview' />,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
			},
		},
	},
	{
		path: trainCognitiveLink,
		component: CognitiveSearch,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainEntitiesLink,
		component: Entities,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainFaqLink,
		component: Faqs,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainConfigSynonymsLink,
		component: ConfigureSynonyms,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainUploadHistoryLink,
		component: UploadHistory,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainCategoriesLink,
		component: FaqCategories,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainOverviewLink,
		component: TrainOverview,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainSettingsLink,
		component: TrainSettings,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainTabularInpLink,
		component: TabularInput,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: configureIntegrationsLink,
		component: Integrations,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: gaIntegrationLink,
		component: GoogleAnalytics,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: googleTranslateIntegrationLink,
		component: GoogleTranslate,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: shiprocketIntegrationLink,
		component: Shiprocket,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: razorpayIntegrationLink,
		component: Razorpay,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: woocommerceIntegrationLink,
		component: WooCommerce,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: gorgiasIntegrationLink,
		component: Gorgias,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: zendeskIntegrationLink,
		component: Zendesk,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: salesforceIntegrationLink,
		component: Salesforce,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: googleAdminIntegrationLink,
		component: GoogleAdmin,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: freshdeskIntegrationLink,
		component: Freshdesk,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: smsIntegrationLink,
		component: Sms,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: eSenseGPTIntegrationLink,
		component: ESenseGPT,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: GPTIntegrationLink,
		component: ESenseGPT,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Integrations',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainResponsesLink,
		component: Responses,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainExpHistoryLink,
		component: ExportHistory,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainFileUplAnalyticsLink,
		component: FileUploadAnalytics,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: trainApiErrorHistoryLink,
		component: ApiErrorHistory,
		options: {
			secondNav: {
				page: 'train',
				heading: 'Train',
				isHeaderShown: false,
			},
		},
	},
	{
		path: configureShopifyLink,
		component: Shopify,
		options: {
			secondNav: {
				page: 'configure',
				heading: 'Shopify',
				isHeaderShown: false,
			},
		},
	},
	{
		path: socialInboxFacebook,
		component: SocialInboxLayout,
		subRoutes: [
			{
				path: socialInboxFacebookPost,
				element: <CommentsListWrapper />,
			},
		],
	},
	{
		path: socialInboxInstagram,
		component: SocialInboxLayout,
		subRoutes: [
			{
				path: socialInboxInstagramPost,
				element: <CommentsListWrapper />,
			},
		],
	},
	{
		path: aiAgentsLink,
		component: AIAgentsRouter,
		options: {
			secondNav: {
				page: 'agents',
			},
		},
	},
	{
		path: aiAgentsManagementLink,
		component: AIAgentManagement,
		options: {
			secondNav: {
				page: 'agents',
			},
		},
	},
	{
		path: createAIAgentLink,
		component: CreateAIAgent,
		options: {
			secondNav: {
				page: 'agents',
			},
		},
	},
	{
		path: viewAgentDetailsLink,
		component: CreateAIAgent,
		options: {
			secondNav: {
				page: 'agents',
			},
		},
	},
	{
		path: agentsWorkflows,
		component: AgentsWorkflows,
		options: {
			secondNav: {
				page: 'agents',
			},
		},
	},
	{
		path: agentsRoute,
		component: AgentsRoute,
		options: {
			secondNav: {
				page: 'agents',
			},
		},
	},
];

export default ThreeColumnRoutes;
