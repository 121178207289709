import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import SupportBots from 'components/SupportBot/SupportBots';
import { useBannerVisiblityFinder } from 'components/UI/ENGTBanner/ENGTBannerContext';
import EmptyPagesLayout from 'components/UI/Layout/EmptyPagesLayout';
import NoColumnLayout from 'components/UI/Layout/NoColumnLayout';
import ThreeColumnLayout from 'components/UI/Layout/ThreeColumnLayout';
import TwoColumnLayout from 'components/UI/Layout/TwoColumnLayout';
import Loader from 'components/UI/Loader/Loader';

import { CSRF_TOKEN_INTERVAL, ENGATI_ROLES, newUserDaysLimit } from 'shared/consts/consts';
import { IRouteProps, ISubRouteProps } from 'shared/consts/types';
import { BOT_MODE, getBannerExcludedPageHeight, getResponsiveVH } from 'shared/helpers';

import { fetchRefreshTokenAction } from 'store/App/Token';
import {
	isShopifyEnabledAcc,
	isWhatsappAcceleratorEnabled,
	isWhatsappAcceleratorTrialEnabled,
} from 'store/App/User/selectors';
import { RootState } from 'store/rootReducer';
import { createdOnSelector, isEngatiBrandSelector, userPhoneNumber } from 'store/Users/selectors';

import ProtectedRoute from 'router/ProtectedRouter/protectedRoute';

import { TwoColumnRouteLinks } from './links/TwoColumnRouteLinks';
import { EmptyPageRoutes } from './EmptyPageRoutes';
import { NoAuthenticationRoutes } from './NoAuthenticationRoutes';
import { NoColumnRouteLinks, NoColumnRoutes } from './NoColumnRoutes';
import ThreeColumnRoutes from './ThreeColumnRoutes';
import TwoColumnRoutes from './TwoColumnRoutes';

const PhoneModal = lazy(() => import('components/PhoneModal/PhoneModal'));

const childRoute = ({ path, element }: ISubRouteProps) => (
	<Route path={path} key={path} element={<Suspense fallback={<Loader />}>{element}</Suspense>} />
);

function threeColumnRouteWithSubRoutes(route: IRouteProps) {
	return (
		<Route
			path={route.path}
			key={route.path}
			element={
				<ProtectedRoute path={route.path}>
					<ThreeColumnLayout>{route.component ? <route.component /> : <></>}</ThreeColumnLayout>
				</ProtectedRoute>
			}
			{...(route.subRoutes && { children: route.subRoutes.map(childRoute) })}
		/>
	);
}

function twoColumnRouteWithSubRoutes(route: IRouteProps) {
	return (
		<Route
			path={route.path}
			key={route.path}
			element={
				<ProtectedRoute path={route.path}>
					<TwoColumnLayout>{route.component ? <route.component /> : <></>}</TwoColumnLayout>
				</ProtectedRoute>
			}
			{...(route.subRoutes && { children: route.subRoutes.map(childRoute) })}
		/>
	);
}

function noColumnRouteWithSubRoutes(route: IRouteProps) {
	return (
		<Route
			path={route.path}
			key={route.path}
			element={
				<ProtectedRoute path={route.path}>
					<NoColumnLayout>{route.component ? <route.component /> : <></>}</NoColumnLayout>
				</ProtectedRoute>
			}
			{...(route.subRoutes && { children: route.subRoutes.map(childRoute) })}
		/>
	);
}

function noAuthenticationRoutesWithSubRoutes(route: IRouteProps) {
	return (
		<Route
			path={route.path}
			key={route.path}
			element={<EmptyPagesLayout>{route.component ? <route.component /> : <></>}</EmptyPagesLayout>}
			{...(route.subRoutes && { children: route.subRoutes.map(childRoute) })}
		/>
	);
}

function tokenOnlyRoutes(route: IRouteProps) {
	return (
		<Route
			path={route.path}
			key={route.path}
			element={
				<ProtectedRoute path={route.path}>
					<EmptyPagesLayout>{route.component ? <route.component /> : <></>}</EmptyPagesLayout>
				</ProtectedRoute>
			}
			{...(route.subRoutes && { children: route.subRoutes.map(childRoute) })}
		/>
	);
}

let csrfTokenTimer: any;
const Router = () => {
	const location = useLocation();
	const [css, theme]: [any, any] = useStyletron();
	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const isLoading = useSelector((state: RootState) => state.Bot.data.loading);
	const botMode = useSelector((state: RootState) => state.Bot.data.mode);
	const role = useSelector((state: RootState) => state.User.profile.data.roleName);
	const isAgent = role === ENGATI_ROLES.ROLE_CONNECT_AGENT;
	const isOwner = role === ENGATI_ROLES.ROLE_CONNECT_OWNER;
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);

	const createdOn = useSelector(createdOnSelector);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);
	const userPhoneNo = useSelector(userPhoneNumber);
	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const createdDate = new Date(createdOn);
	const presentDate = new Date();
	const UserDiffDays = (presentDate.getTime() - createdDate.getTime()) / (1000 * 3600 * 24);
	const csrfToken = localStorage.getItem('csrfToken');
	const [showPhoneModal, setShowPhoneModal] = useState(false);
	const dispatch = useDispatch<any>();

	const bannerHeight = useBannerVisiblityFinder();

	useEffect(() => {
		if (
			botRef &&
			isOwner &&
			!isWhatsappAcceleratorTrial &&
			!userPhoneNo &&
			!showPhoneModal &&
			csrfToken &&
			isEngatiBrand &&
			UserDiffDays < newUserDaysLimit
		) {
			let inactivityTimer: any;

			const startInactivityTimer = () => {
				clearTimeout(inactivityTimer);
				inactivityTimer = setTimeout(() => {
					setShowPhoneModal(true);
				}, 5000);
			};

			window.addEventListener('mousemove', startInactivityTimer);
			window.addEventListener('keydown', startInactivityTimer);

			return () => {
				clearTimeout(inactivityTimer);
				window.removeEventListener('mousemove', startInactivityTimer);
				window.removeEventListener('keydown', startInactivityTimer);
			};
		}
	}, [userPhoneNo, showPhoneModal, location]);

	useEffect(() => {
		if (csrfToken) {
			if (!csrfTokenTimer) {
				csrfTokenTimer = setInterval(() => {
					dispatch(fetchRefreshTokenAction());
				}, CSRF_TOKEN_INTERVAL);
			}
		} else {
			clearInterval(csrfTokenTimer);
		}
	}, [location]);

	useEffect(() => () => clearInterval(csrfTokenTimer), []);

	const waAccHomeRouteRedirects = (path: string) =>
		isWhatsappAcceleratorTrial || isWhatsappAccelerator ? (
			<>
				<Route path='/' element={<ReplaceWith path={path} />} />
				<Route path='/home' element={<ReplaceWith path={path} />} />
			</>
		) : (
			<></>
		);

	const redirectBotsForWaPlg = () => {
		if (isWhatsappAccelerator) {
			if (isAgent) {
				return (
					<Route path={TwoColumnRouteLinks.waQuickNavigator} element={<Navigate to='/messages' replace />} />
				);
			}
			if (!botRef) {
				return <Route path='/' element={<ReplaceWith path='/bots' />} />;
			}
			if (isWhatsappAcceleratorTrial) {
				return <Route path='/bots' element={<Navigate to={TwoColumnRouteLinks.waQuickNavigator} replace />} />;
			}

			return <Route path='/bots' element={<Navigate to={`/bot/${botRef}/overview`} replace />} />;
		}

		return <></>;
	};

	const redirectForShopify = (path: string) => {
		if (isShopifyEnabled !== undefined && role && !isLoading) {
			if (isShopifyEnabled) {
				if (isAgent) {
					return (
						<>
							<Route path='/' element={<ReplaceWith path='/messages' />} />
							<Route path='/home' element={<ReplaceWith path='/messages' />} />
						</>
					);
				}
				if (botRef) {
					if (UserDiffDays > newUserDaysLimit) {
						return (
							<>
								<Route
									path='/'
									element={<ReplaceWith path={`/bot/${botRef}/overview/revenue-dashboard`} />}
								/>
								<Route
									path='/home'
									element={<ReplaceWith path={`/bot/${botRef}/overview/revenue-dashboard`} />}
								/>
							</>
						);
					}

					return (
						<>
							<Route path='/' element={<ReplaceWith path={path} />} />
						</>
					);
				}

				return (
					<>
						<Route path='/' element={<ReplaceWith path='/bots' />} />
					</>
				);
			}
		} else {
			return (
				<>
					<Route path='/' element={<Loader loaderBlockHeight={getResponsiveVH(100)} />} />
				</>
			);
		}
	};

	return (
		<>
			{showPhoneModal && (
				<Suspense fallback={<Loader />}>
					<PhoneModal />
				</Suspense>
			)}
			<SupportBots />
			{botMode === BOT_MODE.DRAFT && (
				<div
					className={css({
						borderTop: `0.25rem solid ${theme.colors.horizantalDividerColor}`,
					})}
				/>
			)}
			<Block minHeight={getBannerExcludedPageHeight(bannerHeight)}>
				<Routes>
					{redirectBotsForWaPlg()}
					{TwoColumnRoutes.map(twoColumnRouteWithSubRoutes)}
					{ThreeColumnRoutes.map(threeColumnRouteWithSubRoutes)}
					{NoColumnRoutes.map(noColumnRouteWithSubRoutes)}
					{NoAuthenticationRoutes.map(noAuthenticationRoutesWithSubRoutes)}
					{EmptyPageRoutes.map(tokenOnlyRoutes)}
					{waAccHomeRouteRedirects(TwoColumnRouteLinks.waQuickNavigator)}
					{redirectForShopify(TwoColumnRouteLinks.quickNavigator)}
					{!isAgent ? (
						<>
							<Route path='/home' element={<Navigate to={`/bot/${botRef}/overview`} replace />} />
							<Route path='/configure' element={<Navigate to='/configure/overview' replace />} />
						</>
					) : (
						<></>
					)}
					{isAgent ? (
						<>
							<Route path='/' element={<ReplaceWith path='/messages' />} />
							<Route path='/home' element={<ReplaceWith path='/messages' />} />
						</>
					) : (
						<></>
					)}
					{isShopifyEnabled !== undefined ? <Route path='/' element={<ReplaceWith path='/bots' />} /> : <></>}
					<Route path='*' element={<Navigate to={NoColumnRouteLinks.noPageFound} />} />
				</Routes>
				<Outlet />
			</Block>
		</>
	);
};

export const ReplaceWith = ({ path }: { path: string }) => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(path, { replace: true });
	}, [history, path]);

	return <></>;
};

export default Router;
